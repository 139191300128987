import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import React, {useState, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {handleResponse} from '../utils/utils';
import { Button, Typography, Grid, Paper } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DialogLoading from '../utils/DialogLoading';

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

import sendImg from '../images/send.png';
import imgNoCalendar from "../images/no_calendar.png";

import { useNavigate, useSearchParams } from 'react-router-dom';

import {scroller as scroll, scrollSpy, Element, Link as LinkScroll} from 'react-scroll';

const EventsPage = (props) => {
    const {user, setUser} = props;

    const [events, setEvents] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listePlus, setListePlus] = useState([]);


    const [useParam, ] = useSearchParams();

    const idEvent = useParam.get("id");
    let dateEvent = useParam.get("date");
    if(dateEvent){
        dateEvent = new Date(dateEvent);
        currentMonth = dateEvent;
    }

    const navigate = useNavigate();

    const loadEvents = (currentMonth) => {
        //récupération des événements en question
        fetch("/api/offices/events?date="+encodeURIComponent(currentMonth), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setEvents(data.data.reverse());
                    console.log("Events:", data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })

        //récupération du calendrier du mois
        setCalendar(null);
        fetch("/api/offices/calendar?date="+encodeURIComponent(currentMonth), {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data===null){
                    setCalendar(undefined);
                }else{
                    setCalendar(data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const [currentMonth, setCurrentMonth] = useState(new Date());

    const previousMonth = () => {
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setCurrentMonth(prevMonth);
        setEvents(null);
        setCalendar(null);
        loadEvents(prevMonth);
        setFichierSelectionne(null);
    }

    const nextMonth = () => {
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setCurrentMonth(nextMonth);
        setEvents(null);
        setCalendar(null);
        loadEvents(nextMonth);
        setFichierSelectionne(null);
    };

    let monthName = new Intl.DateTimeFormat('fr', { month: 'long' }).format(currentMonth);
    monthName = monthName[0].toUpperCase()+monthName.substring(1);

    useEffect(() => {
        loadEvents(currentMonth);
    }, []);

    const [fichierSelectionne, setFichierSelectionne] = useState(null);
    const handleChangementFichier = (e) => {
        const fichierSelectionne = e.target.files[0];
        if (fichierSelectionne) {
            // Vérifiez la taille du fichier (10 Mo maximum)
            const tailleMax = 10 * 1024 * 1024; // 10 Mo en octets
            if (fichierSelectionne.size <= tailleMax) {
            // Vérifiez le type de fichier avant de l'accepter
            if (
                fichierSelectionne.type === 'image/png' ||
                fichierSelectionne.type === 'image/jpeg' ||
                fichierSelectionne.type === 'image/jpg'
            ) {
                setFichierSelectionne(fichierSelectionne);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
            } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
            }
        }
    };

    const handleCalendar = () => {
        if(calendar){
            //update
            setLoading(true);
            const formdata = new FormData();
            formdata.append("image", fichierSelectionne);
            formdata.append("date", currentMonth);
            formdata.append("id", calendar.id);
            fetch("/api/me/updateCalendar", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formdata 
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadEvents(currentMonth);
                    setFichierSelectionne(null);
                }else{

                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })

        }else{
            const formdata = new FormData();
            formdata.append("image", fichierSelectionne);
            formdata.append("date", currentMonth);
            setLoading(true);
            fetch("/api/me/addCalendar", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formdata 
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadEvents(currentMonth);
                    setFichierSelectionne(null);
                }else{

                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const handleDel = () => {
        if(calendar){
            setLoading(true);
            fetch("/api/me/delCalendar", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: calendar.id
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadEvents(currentMonth);
                    setFichierSelectionne(null);
                }else{

                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const handleCancel = () => {
        setFichierSelectionne(null);
    }

    const [dialogNotif, setDialogNotif] = useState(null);

    const handleSendNotif = (id) => {
        if(localStorage.getItem("token")&&user&&user.is_admin){
            setLoading(true);
            fetch("/api/subscriptions/notif/force_one", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id,
                    type: "event"
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){

                }else{

                }
            })).catch(e => {
                setLoading(false);
                //NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
        handleClose();
        //window.location.reload(false);
    }

    const handleClose = () => {
        setDialogNotif(null);
    }

    const handlePlus = (key) => {
        setListePlus(listePlus => [...listePlus, key]);
    }

    const handleMinus = (key) => {
        setListePlus(listePlus => listePlus.filter(elt => key!=elt));
    }

    const datePlus2 = new Date();
    datePlus2.setDate(datePlus2.getDate()+2);
    datePlus2.setHours(23);
    datePlus2.setMinutes(59);
    datePlus2.setSeconds(59);

    const dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);

    const actualMonth = new Date(dateNow);

    return (<div className="page" id="eventsPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <NavBar titre="Événements"/>

        <div className="information" id="information">
            <Paper elevation={3} className="calendar-head">
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="h6">
                Calendrier de {monthName}
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                </Button>
            </Grid>
            </Grid>
            </Paper>
            <div className="calendar-img">
                {user&&user.is_admin&&<div className="composant-telechargement-fichier">
                    <label className={`label-fichier ${fichierSelectionne ? 'selected' : ''}`}>
                        <input
                        type="file"
                        className="input-fichier"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleChangementFichier}
                        />
                        <span className="texte-label-fichier">
                        {fichierSelectionne ? 'Image sélectionnée' : 'Choisissez une image'}
                        </span>
                    </label>
                </div>}
                {calendar&&!fichierSelectionne?<>
                    <img src={calendar.picture}/>
                    {user&&user.is_admin&&<div className="buttons">
                        <button className="del-button" onClick={handleDel}>Supprimer</button>
                    </div>}
                </>:(fichierSelectionne)?<>
                    <img src={URL.createObjectURL(fichierSelectionne)}/>
                </>:(calendar===null)?<>
                        <Skeleton className="skee" />
                        {/* <img alt="no-calendar" src={imgNoCalendar} /> */}
                </>:<>
                    <img alt="no-calendar" src={imgNoCalendar} />
                </>}
                {fichierSelectionne?<>
                        <div className="buttons">
                            <button className="edit-button" onClick={handleCalendar}>Sauvegarder</button>
                            <button className="del-button" onClick={handleCancel}>Annuler</button>
                        </div>
                </>:<>
                    {(calendar!==null)&&!calendar&&<p>Pas encore de calendrier disponible pour ce mois...</p>}
                </>}
            </div>
            
            {user&&user.is_admin&&<Accordion id="notif-info">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Gérer les notifications</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Vous pouvez forcer l'envoi de notification pour un événement en cliquant sur le bouton prévu à cet effet.<br/>
                Cela vous demandera de confirmer puis enverra la notification sur tous les téléphones disponibles.
                </Typography>
                </AccordionDetails>
            </Accordion>}
            
            
            
            <div className="events">
                {(events == null) ? (
                    <div className="element">
                        <h1><Skeleton /></h1>
                        <Skeleton height={200} />
                        <p><Skeleton count={5} /></p>
                    </div>
                ) : ((events.length === 0) ? (
                    <p>Il n'y a pas encore d'événement prévu...</p>
                ) : (<>
                    {(actualMonth.getMonth()<=currentMonth.getMonth())&&<>
                    <fieldset >
                        <legend>Événéments actuels (J à J+2)</legend>
                        
                        {(events.filter(elt => (new Date(elt.date)) >= dateNow && (new Date(elt.date)) <= datePlus2).length==0)?(<>
                            <p className="nullState">Aucun pour le moment</p>
                        </>):(events.filter(elt => (new Date(elt.date)) >= dateNow && (new Date(elt.date)) <= datePlus2).map((elt, key) => {
                            const long_description = elt.description.split("\n").filter(elt => elt!="").length > 3;
                            return (<div key={key} className={"element"+((elt.id==idEvent)?" focus":"")}>
                            <Element name={elt.id} isDynamic={true}></Element>
                                <div className="head">
                                    <h1>{elt.title}</h1>
                                    <div className="entity-info">
                                        <img src={elt.entity.picture} alt={elt.entity.name} className="entity-logo" />
                                        <p className="entity-name">{elt.entity.name}</p>
                                    </div>
                                </div>
                                <div className="event-image">
                                    <img src={elt.picture} alt={elt.title} />
                                </div>
                                <div className="event-details"> 
                                    <p className="event-description">{(long_description&&!(listePlus.indexOf(key)!=-1))?((elt.description.split("\n").filter((elt, k) => 0 <= k && k < 3).join("\n"))):elt.description}{long_description&&<strong className="description-plus"><button onClick={(!(listePlus.indexOf(key)!=-1))?() => handlePlus(key):()=> handleMinus(key)}>... {(!(listePlus.indexOf(key)!=-1))?"plus":"moins"}</button></strong>}</p>
                                    <div className="date-details">
                                        <p className="event-date">Date: {new Date(elt.date).toLocaleString('fr-FR', { dateStyle: 'long', timeStyle: 'short' })}</p>
                                        <p>De <span>{elt.poster.name+" "+elt.poster.last_name}</span></p>
                                    </div>
                                </div>
                                {user&&user.is_admin&&<button className="notif-button" onClick={() => setDialogNotif(elt.id)}>Envoi de notification</button>}
                            </div>
                        )}))}
                    </fieldset>
                    
                    <h3>À venir</h3>

                    {events.filter(elt =>  (new Date(elt.date)) > datePlus2).map((elt, key) => {
                        const long_description = elt.description.split("\n").filter(elt => elt!="").length > 3;
                        return (<div key={key} className={"element"+((elt.id==idEvent)?" focus":"")}>
                            <Element name={elt.id} isDynamic={true}></Element>
                            <div className="head">
                                <h1>{elt.title}</h1>
                                <div className="entity-info">
                                    <img src={elt.entity.picture} alt={elt.entity.name} className="entity-logo" />
                                    <p className="entity-name">{elt.entity.name}</p>
                                </div>
                            </div>
                            <div className="event-image">
                                <img src={elt.picture} alt={elt.title} />
                            </div>
                            <div className="event-details"> 
                                <p className="event-description">{(long_description&&!(listePlus.indexOf(key)!=-1))?((elt.description.split("\n").filter((elt, k) => 0 <= k && k < 3).join("\n"))):elt.description}{long_description&&<strong className="description-plus"><button onClick={(!(listePlus.indexOf(key)!=-1))?() => handlePlus(key):()=> handleMinus(key)}>... {(!(listePlus.indexOf(key)!=-1))?"plus":"moins"}</button></strong>}</p>
                                <div className="date-details">
                                    <p className="event-date">Date: {new Date(elt.date).toLocaleString('fr-FR', { dateStyle: 'long', timeStyle: 'short' })}</p>
                                    <p>De <span>{elt.poster.name+" "+elt.poster.last_name}</span></p>
                                </div>
                            </div>
                            {user&&user.is_admin&&<button className="notif-button" onClick={() => setDialogNotif(elt.id)}>Envoi de notification</button>}
                        </div>
                    )})}

                    </>}
                    
                    {(actualMonth.getMonth()>=currentMonth.getMonth())&&<>
                        <Accordion id="old-info" defaultExpanded={(actualMonth.getMonth()>currentMonth.getMonth()) || idEvent!=null}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Événéments passés du mois</Typography>
                            </AccordionSummary>
                            <AccordionDetails id="accordion">
                                <div className="events">
                                    {[...events].reverse().filter(elt =>  (new Date(elt.date)) < dateNow).map((elt, key) => {
                                        const long_description = elt.description.split("\n").filter(elt => elt!="").length > 3;
                                        return (<div key={key} className={"element"+((elt.id==idEvent)?" focus":"")}>
                                        <Element name={elt.id} isDynamic={true}></Element>
                                            <div className="head">
                                                <h1>{elt.title}</h1>
                                                <div className="entity-info">
                                                    <img src={elt.entity.picture} alt={elt.entity.name} className="entity-logo" />
                                                <p className="entity-name">{elt.entity.name}</p>
                                            </div>
                                        </div>
                                        <div className="event-image">
                                            <img src={elt.picture} alt={elt.title} />
                                        </div>
                                        <div className="event-details"> 
                                            <p className="event-description">{(long_description&&!(listePlus.indexOf(key)!=-1))?((elt.description.split("\n").filter((elt, k) => 0 <= k && k < 3).join("\n"))):elt.description}{long_description&&<strong className="description-plus"><button onClick={(!(listePlus.indexOf(key)!=-1))?() => handlePlus(key):()=> handleMinus(key)}>... {(!(listePlus.indexOf(key)!=-1))?"plus":"moins"}</button></strong>}</p>
                                            <div className="date-details">
                                                <p className="event-date">Date: {new Date(elt.date).toLocaleString('fr-FR', { dateStyle: 'long', timeStyle: 'short' })}</p>
                                                <p>De <span>{elt.poster.name+" "+elt.poster.last_name}</span></p>
                                            </div>
                                        </div>
                                        {user&&user.is_admin&&<button className="notif-button" onClick={() => setDialogNotif(elt.id)}>Envoi de notification</button>}
                                    </div>
                                    )})}     
                                </div>
                            </AccordionDetails>
                        </Accordion>     
                    </>}   
                    </>
                ))}
            </div>
            <Dialog
                open={user&&user.is_admin?dialogNotif:false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={(user&&user.is_admin?dialogNotif:false)?"":"dialogNotifHide"}
            >
                <DialogTitle id="alert-dialog-title">
                {"Envoi de notification"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Etes vous sûr d'envoyer une notification à tous les téléphones pour cet événement ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} autoFocus>Nope</Button>
                <Button onClick={() => handleSendNotif(dialogNotif)} >
                    LET'S GO
                </Button>
                </DialogActions>
            </Dialog>
            <div className="bottom"></div>
            
        </div>
        {
            setTimeout(() => {
                scroll.scrollTo(idEvent, {
                    offset: -30,
                    containerId: "information",
                });

            }, 300)
        }
        <Menu />
    </div>)
}


export default EventsPage;
