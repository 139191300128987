import '../css/Nav.css';

import { Link, useNavigate } from 'react-router-dom';
import notifImg from '../images/notification.png';
import arrowImg from '../images/left-arrow-bis.png';

const Nav = (props) => {
    const {titre, psn, removeBack} = props;

    const navigate = useNavigate();

    return (<header className="navbar" style={psn?{backgroundColor: "#006FC0"}:{}}>
        <div id="main-nav">
            {!removeBack?<button onClick={() => navigate(-1)}>
                <img src={arrowImg} alt="<-"/>
            </button>:<div></div>}
            <Link to="/subscription">
                <img src={notifImg} alt="notif"/>
            </Link>
        </div>
        <h2>{titre}</h2>
    </header>)
}


export default Nav;