import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';

import closeImg from '../images/close.png';

export default function DialogLoading(props){
    const {active, setActive} = props;

    return (<div id="dialogLoading" className={active?"":" stop"}>
        <div className="closeBox">
            <button onClick={()=>setActive(value=>!value)}>
                <img src={closeImg} alt='X'/>
            </button>
        </div>
        <div className="content">
            <ReactLoading type="spokes" color="#fff"/>
            {/* <p>Chargement...</p> */}
        </div>
    </div>);
}