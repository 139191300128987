import React from 'react';

import leftImg from '../images/left-arrow.png';

export default function Privacy(){
    

    return <div className="page" id="privacyPage">
        <div className="information">
            <div className="elt">
                <a href="/"><img src={leftImg} /></a>
            </div>

            <h1>Politique de confidentialité pour l'application de gestion des événements de CDE Polytech Nancy</h1>

            <h2>Collecte et utilisation des informations</h2>
            <p>Notre application de gestion des événements de CDE Polytech Nancy collecte certaines informations personnelles pour offrir une expérience personnalisée et faciliter la participation aux événements. Les types d'informations collectées incluent :</p>
            <ul>
                <li><strong>Nom et Prénom :</strong> Ces informations sont collectées pour identifier les participants aux événements et faciliter la communication.</li>
                <li><strong>Email :</strong> Nous demandons votre adresse e-mail pour vous informer des événements à venir, pour vous envoyer des confirmations de participation et pour toute communication pertinente liée aux événements de l'école.</li>
            </ul>

            <h2>Sécurité des données</h2>
            <p>Nous accordons une grande importance à la sécurité de vos informations personnelles. Toutes les informations collectées sont stockées de manière sécurisée et ne sont accessibles qu'aux personnes autorisées nécessitant ces données pour l'organisation des événements.</p>

            <h2>Partage d'informations</h2>
            <p>Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement explicite, sauf si cela est requis par la loi.</p>

            <h2>Cookies</h2>
            <p>Notre application peut utiliser des cookies pour améliorer votre expérience utilisateur. Ces cookies ne collectent pas d'informations personnelles identifiables.</p>

            <h2>Droits des utilisateurs</h2>
            <p>Vous avez le droit d'accéder, de rectifier ou de supprimer vos informations personnelles stockées dans notre application. Pour exercer ces droits ou pour toute question concernant notre politique de confidentialité, veuillez nous contacter à l'adresse email de contact.</p>

            <h2>Consentement</h2>
            <p>En utilisant notre application de gestion des événements de CDE Polytech Nancy, vous consentez à notre collecte et à notre utilisation des informations personnelles comme décrit dans cette politique de confidentialité.</p>

            <p>Cette politique de confidentialité est sujette à des mises à jour et des modifications périodiques. Les changements importants seront notifiés via notre application ou par email.</p>

            <p>Date d'entrée en vigueur : 25 novembre 2023</p>
            <div className='bottom'></div>
        </div>
    </div>
}