import React from 'react';
import { NotificationManager } from 'react-notifications';

import {Route, useNavigate, Navigate} from 'react-router-dom';

const PrivateRoute = ({user, children, access}) => {

    const navigate = useNavigate();

    if(!localStorage.getItem("token")){
        return (<Navigate to="/login" replace/>)
    }
    if(!user){
        return (<div></div>);
    }
    if(user&&!user.is_admin&&(access==null||!access)){
        NotificationManager.info("Vous n'êtes pas autorisé à accéder à cette page.");
        return (<Navigate to="/" replace/>)
    }
    return (children);

};

export default PrivateRoute;