import React, {useState, useEffect} from 'react';

import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import { handleResponse } from '../utils/utils';

import plusImg from '../images/plus.png';
import minusImg from '../images/close.png';

import { NotificationManager } from 'react-notifications';
import DialogLoading from '../utils/DialogLoading';

import { TextField, TextareaAutosize} from '@mui/material';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material';


export default (props) => {
    const {user, setUser} = props;


    const [data, setData] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [picture, setPicture] = useState(null);
    const [preview, setPreview] = useState(null);

    const [description, setDescription] = useState("");
    const [titre, setTitre] = useState("");
    const [price, setPrice] = useState(0);

    const [modification, setModification] = useState(null);
    const [loading, setLoading] = useState(false);

    const [dialogSupp, setDialogSupp] = useState(null);

    const launchDelete = (id) => {
        setDialogSupp(id);
    };
    const handleClose = () => {
        setDialogSupp(null);
    }

    const loadData = () => {
        fetch("/api/goodies", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(data => (handleResponse(data,false,(data) => {
            if(data&&data.data){
                setData(data.data);
            }
        }))).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleProfil = (e) => {
        setPicture(e.target.files[0]);
        if(preview){
            URL.revokeObjectURL(preview);
        }
        setPreview(URL.createObjectURL(e.target.files[0]));
    };


    const addPartenaire = () => {
        if(user&&user.is_admin){
            setLoading(true);
            const formData = new FormData();
            formData.append("image", picture);
            formData.append("description", description);
            formData.append("titre", titre);
            formData.append("price", price);

            fetch("/api/goodies/add", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formData
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                loadData();

                if(data){
                    handleBack();
                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const handleDelete = (id) => {
        if(user&&user.is_admin){
            setLoading(true);
            fetch("/api/goodies/del", {
                method: "POST",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                loadData();

            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
        setDialogSupp(null);
    };

    const handleUpdate = (id) => {
        if(user&&user.is_admin){
            setLoading(true);
            const formdata = new FormData();
            formdata.append("titre", titre);
            formdata.append("id", id);
            formdata.append("description", description);
            formdata.append("price", price);
            if(picture){
                formdata.append("image", picture);
            }
            fetch("/api/goodies/update", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formdata
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                loadData();
                handleBack();
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const launchUpdate = (id) => {
        if(user&&user.is_admin){
            setLoading(true);
            fetch("/api/goodies/one?id="+encodeURIComponent(id), {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                setLoading(false);
                if(data.data){
                    const {name, price, description, id, picture} = data.data;
                    setTitre(name);
                    setPrice(price);
                    setDescription(description);
                    
                    setPreview(picture);

                    setModification(id);
                    setDialog(true);
                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const handleBack = () => {
        setDialog(false);
        setModification(null);
        setTitre("");
        setDescription("");
        setPrice(0);
        setPreview(null);
        setPicture(null);
    }

    return (<div className="page" id="serviceGoodiesPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <NavBar titre="Goodies"/>
        <div className="information">
        {(!dialog)?<>
                {user&&user.is_admin&&<>
                    <button onClick={() => setDialog(elt => !elt)}><img src={plusImg} />Ajouter</button>
                </>}
                <h4>Voici tous les goodies proposés par le cercle</h4>
                <div className="goodies">
                {data!=null&&data.length!=0?<>
                    {
                        data&&data.map((elt, key) => {

                            return (<div key={key} className="element">
                                    <div className="top">
                                        <h3>{elt.name}</h3>
                                        {user&&user.is_admin&&<button onClick={() => launchDelete(elt.id)}><img src={minusImg}/></button>}
                                    </div>
                                    <img alt="logo" src={elt.picture} />
                                    <p><strong>{elt.price}</strong> €</p>
                                    <pre>{elt.description}</pre>
                                    {user&&user.is_admin&&<button className="button-modifier" onClick={() => launchUpdate(elt.id)}>Modifier</button>}
                            </div>);
                        })
                    }
                </>:(data==null)&&<>
                    <div className="element fake">
                        <div className="top">
                            <h3><Skeleton /></h3>
                        </div>
                        <div className="top">
                            <h3><Skeleton height={80} /></h3>
                        </div>
                    </div>
                    <div className="element fake">
                        <div className="top">
                            <h3><Skeleton /></h3>
                        </div>
                        <div className="top">
                            <h3><Skeleton height={80} /></h3>
                        </div>
                    </div>
                </>}
                </div>
                {(data&&data.length==0)&&<>
                    <p>Il n'y pas encore de goodies en stock, mais ça ne va pas tarder :)</p>
                </>}
            </>:<>
                <button onClick={handleBack}>Retour</button>

                <div className="configuration">
                    <div className="elt">
                        <TextField
                            label="Titre"
                            variant="outlined"
                            value={titre}
                            onChange={(e) => setTitre(e.target.value)}
                        />
                    </div>
                    <div className="elt">
                        <TextField
                            label="Prix du goodies"
                            value={price}
                            type="number"
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>
                    <div className="elt logo">
                        {preview && <img src={preview} alt="Preview" />}
                        <label for="image" className={`file-input-label ${preview ? 'selected' : ''}`}>
                        {preview ? 'Image selectionnée' : 'Choisir une image'}
                        </label>
                        <input
                        type="file"
                        id="image"
                        name="image"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleProfil}
                        />
                    </div>
                    <div className="elt textarea">
                        <label for="description">Description du goodies</label>
                        <TextareaAutosize
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            minRows={3}
                        />
                    </div>

                    {modification?<button onClick={() => handleUpdate(modification)}>Modifier</button>:<button onClick={addPartenaire}><img src={plusImg}/>Ajouter</button>}
                </div>
            </>}

            <div className="bottom"></div>
        </div>

        <Menu />

        <Dialog
            open={user&&user.is_admin?dialogSupp:false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Suppression goodies"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Etes vous sûr de supprimer ce goodies?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} autoFocus>Nope</Button>
            <Button onClick={() => handleDelete(dialogSupp)} >
                OUI
            </Button>
            </DialogActions>
        </Dialog>
    </div>);
};