import React, {useState, useEffect} from 'react';

import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import backImg from '../images/left-arrow.png';
import calendarImg from '../images/calendar-days.png';

import { handleResponse } from '../utils/utils';

import plusImg from '../images/plus.png';
import minusImg from '../images/close.png';
import annonceImg from '../images/megaphone.png';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import { NotificationManager } from 'react-notifications';

import { TextField, Button, Container, Card, CardContent, Typography, Box, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import {scroller as scroll, Element} from 'react-scroll';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



import { Grid, Paper } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DialogLoading from '../utils/DialogLoading';


export default (props) => {
    const {user, setUser} = props;


    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [heure, setHeure] = useState(null);

    const [editPostId, setEditPostId] = useState(null);
    const [editEventId, setEditEventId] = useState(null);
    const [dateMod, setDateMod] = useState('');
    const [heureMod, setHeureMod] = useState(null);

    const [listePlus, setListePlus] = useState([]);

    const [currentMonth, setCurrentMonth] = useState(new Date());

    const previousMonth = () => {
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        if(club){
            loadAnnonces(club.id, "club", prevMonth);
            loadEvents(club.id, "club", prevMonth);
        }else{
            loadAnnonces(bureau.id, "office", prevMonth);
            loadEvents(bureau.id, "office", prevMonth);
        }
        setCurrentMonth(prevMonth);
        setListePlus([]);
    };

    const nextMonth = () => {
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        if(club){
            loadAnnonces(club.id, "club", nextMonth);
            loadEvents(club.id, "club", nextMonth);
        }else{
            loadAnnonces(bureau.id, "office", nextMonth);
            loadEvents(bureau.id, "office", nextMonth);
        }
        setCurrentMonth(nextMonth);
        setListePlus([]);
    };

    let monthName = new Intl.DateTimeFormat('fr', { month: 'long' }).format(currentMonth);
    monthName = monthName[0].toUpperCase()+monthName.substring(1);

    const handleAddPost = () => {
        setLoading(true);
        fetch("/api/me/addPost", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: (id_bureau)?id_bureau:id_club,
                title,
                description,
                type: (id_bureau)?"office":"club"
            })
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadAnnonces(id_bureau, "office", currentMonth);
                }else{
                    loadAnnonces(id_club, "club", currentMonth);
                }
                setTitle("");
                setDescription("");
            }else{

            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };
    
    const handleAddEvent = () => { 
        const formdata = new FormData();
        formdata.append("id", (id_bureau)?id_bureau:id_club);
        formdata.append("title", title);
        formdata.append("description", description);
        formdata.append("type", (id_bureau)?"office":"club");
        formdata.append("date", date);
        formdata.append("image", fichierSelectionne);
        
        formdata.append("hour", heure);
        setLoading(true);
        fetch("/api/me/addEvent", {
            method: "POST",
            headers: {
                token: localStorage.getItem("token")
            },
            body: formdata
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadEvents(id_bureau, "office", currentMonth);
                }else{
                    loadEvents(id_club, "club", currentMonth);
                }
                setTitle("");
                setDescription("");
                setDate("");
                setFichierSelectionne(null);
            }else{

            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const [annonces, setAnnonces] = useState(null);
    const loadAnnonces = (id, type, month) => {
        fetch("/api/offices/posts?id="+encodeURIComponent(id)+"&type="+type+"&month="+encodeURIComponent(month), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setAnnonces(data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const [events, setEvents] = useState(null);
    const loadEvents = (id, type, month) => {
        fetch("/api/offices/events?id="+encodeURIComponent(id)+"&type="+type+"&date="+encodeURIComponent(month), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setEvents(data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }


    const [data, setData] = useState(null); 

    const [useParam, ] = useSearchParams();
    const navigate = useNavigate();
    
    const id_bureau = useParam.get("id");
    const [idBureau, setIdBureau] = useState(null);
    const [bureau, setBureau] = useState(null);


    const id_club = useParam.get("id_club");
    const [idClub, setIdClub] = useState(null);
    const [club, setClub] = useState(null);

    const [selectOption, setSelectOption] = useState("Evenement");

    const [titleMod, setTitleMod] = useState("");
    const [descriptionMod, setDescriptionMod] = useState("");


    const loadData = () => {
        if(user){
            fetch("/api/me/myentities", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setData(data.data);
                    }
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }


    const [members, setMembers] = useState(null);
    const loadMembers = () => {
        fetch("/api/members/all", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setMembers(data.data);
                }
            }else{
                setMembers(null);
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadClub = (id_club) => {
        fetch("/api/offices/club/one?id="+encodeURIComponent(id_club), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data&&data.data){
                setClub(data.data);
                scroll.scrollTo("top", {
                    containerId: "information"
                });
                setTitlePage(data.data.name);
                setDescriptionPage(data.data.description);
            }else{
                setIdClub(null);
                setClub(null);
                navigate("");
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    } 


    //on charge le club
    if(id_club!=idClub&&id_club){
        setClub(null);
        setIdClub(id_club);
        loadAnnonces(id_club, "club", currentMonth);
        loadEvents(id_club, "club", currentMonth);
        loadMembers();
        loadClub(id_club);
    }

    const loadBureau = (id_bureau) => {
        fetch("/api/offices/one?id="+encodeURIComponent(id_bureau), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data&&data.data){
                setBureau(data.data);
                setTitlePage(data.data.name);
                setDescriptionPage(data.data.description);
            }else{
                setIdBureau(null);
                setBureau(null);
                navigate("");
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    //on charge le bureau
    if(id_bureau!=idBureau&&id_bureau){
        setBureau(null);
        setIdBureau(id_bureau);
        loadAnnonces(id_bureau, "office", currentMonth);
        loadEvents(id_bureau, "office", currentMonth);
        loadMembers();
        loadBureau(id_bureau);
    }

    const [fichierSelectionne, setFichierSelectionne] = useState(null);
    const [fichierSelectionneMod, setFichierSelectionneMod] = useState(null);

    const handleChangementFichier = (e) => {
        const fichierSelectionne = e.target.files[0];
        if (fichierSelectionne) {
            // Vérifiez la taille du fichier (10 Mo maximum)
            const tailleMax = 10 * 1024 * 1024; // 10 Mo en octets
            if (fichierSelectionne.size <= tailleMax) {
            // Vérifiez le type de fichier avant de l'accepter
            if (
                fichierSelectionne.type === 'image/png' ||
                fichierSelectionne.type === 'image/jpeg' ||
                fichierSelectionne.type === 'image/jpg'
            ) {
                setFichierSelectionne(fichierSelectionne);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
            } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
            }
        }
    };

    const handleChangementFichierMod = (e) => {
        const fichierSelectionne = e.target.files[0];
        if (fichierSelectionne) {
            // Vérifiez la taille du fichier (10 Mo maximum)
            const tailleMax = 10 * 1024 * 1024; // 10 Mo en octets
            if (fichierSelectionne.size <= tailleMax) {
            // Vérifiez le type de fichier avant de l'accepter
            if (
                fichierSelectionne.type === 'image/png' ||
                fichierSelectionne.type === 'image/jpeg' ||
                fichierSelectionne.type === 'image/jpg'
            ) {
                setFichierSelectionneMod(fichierSelectionne);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
            } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
            }
        }
    };
      

    const handleBack = () => {
        navigate("");
        setTitle("");
        setDescription("");
        setClub(null);
        setBureau(null);
        setIdBureau(null);
        setIdClub(null);
        setDate("");
        setSelectOption("Annonce");
        setImagePage(null);
    }
    
    const handleChange = (e) => {
        setSelectOption(e.target.value);
        setSearch("");
        setImagePage(null);
        const newMonth = new Date();
        setCurrentMonth(newMonth);
        if(id_bureau){
            loadAnnonces(id_bureau, "office", newMonth);
            loadEvents(id_bureau, "office", newMonth);
        }else{
            loadAnnonces(id_club, "club", newMonth);
            loadEvents(id_club, "club", newMonth);
        }
    }

    const handleCancel = () => {
        setTitleMod("");
        setDescriptionMod("");
        setEditPostId(null);
    }

    const handleUpdatePost = () => {
        setLoading(true);
        fetch(`/api/me/editPost/${editPostId}`, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                title: titleMod,
                description: descriptionMod
            })
        })
        .then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if (data) {
                if(id_bureau){
                    loadAnnonces(id_bureau, "office", currentMonth);
                }else{
                    loadAnnonces(id_club, "club", currentMonth);
                }
                handleCancel();
            } else {
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const handleDel = (postId) => {
        setLoading(true);
        fetch(`/api/me/deletePost/${postId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token")
          }
        })
          .then((res) => handleResponse(res, true, (data) => {
            setLoading(false);
            if (data) {
                if(id_bureau){
                    loadAnnonces(id_bureau, "office", currentMonth);
                }else{
                    loadAnnonces(id_club, "club", currentMonth);
                }
            } else {
            }
          }))
          .catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
      };

    const handleEditPost = (post) => {
        setTitleMod(post.title);
        setDescriptionMod(post.description);
        setEditPostId(post.id);
        setEditEventId(null);
    }


    const handleDelEvent = (id) => {
        setLoading(true);
        fetch("/api/me/deleteEvent/"+id, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadEvents(id_bureau, "office", currentMonth);
                }else{
                    loadEvents(id_club, "club", currentMonth);
                }
            }else{

            }
        }))
        .catch(e => {
          setLoading(false);
          NotificationManager.warning("Il y a eu une erreur côté serveur...");
      })
    }

    const handleEditEvent = (elt) => {
        setTitleMod(elt.title);
        setDescriptionMod(elt.description);

        const newdate = new Date(elt.date);
        setDateMod(newdate.toISOString().split("T")[0]);
        const newFormatHour = newdate.getHours().toString().padStart(2, '0')+":"+newdate.getMinutes().toString().padStart(2, '0');
        setHeureMod(newFormatHour);

        setEditPostId(null);
        setEditEventId(elt.id);
    }

    const handleCancelEvent = () => {
        setTitleMod("");
        setDescriptionMod("");
        setEditEventId(null);
        setDateMod("");
        setHeureMod("");
        setFichierSelectionneMod(null);
    }

    const handleSendEditEvent = () => {
        setLoading(true);
        const formdata = new FormData();

        formdata.append("title", titleMod);
        formdata.append("description", descriptionMod);
        
        let newdate = new Date(dateMod);
        if(heureMod){
            newdate.setHours(parseInt(heureMod.split(":")[0]));
            newdate.setMinutes(parseInt(heureMod.split(":")[1]));
        }
        formdata.append("date", newdate);
        if(fichierSelectionneMod){
            formdata.append("image", fichierSelectionneMod);
        }
        fetch(`/api/me/editEvent/${editEventId}`, {
            method: "POST", 
            headers: {
                token: localStorage.getItem("token")
            },
            body: formdata
        })
        .then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if (data) {
                if(id_bureau){
                    loadEvents(id_bureau, "office", currentMonth);
                }else{
                    loadEvents(id_club, "club", currentMonth);
                }
                handleCancelEvent();
            } else {
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }
    
    const handleDelMember = (id) => {
        fetch("/api/me/delMember/"+id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: ((id_bureau)?id_bureau:id_club),
                type: ((id_bureau)?"office":"club")
            })
        }).then(res => handleResponse(res, true, (data) => {
            if(data){
                if(id_bureau){
                    loadBureau(id_bureau);
                }else{
                    loadClub(id_club);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }
    const [search, setSearch] = useState("");

    const handleAddMember = (id) => {
        fetch("/api/me/addMember/"+id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: ((id_bureau)?id_bureau:id_club),
                type: ((id_bureau)?"office":"club")
            })
        }).then(res => handleResponse(res, true, (data) => {
            if(data){
                if(id_bureau){
                    loadBureau(id_bureau);
                }else{
                    loadClub(id_club);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const [titlePage, setTitlePage] = useState('');
    const [descriptionPage, setDescriptionPage] = useState('');
    const [imagePage, setImagePage] = useState(null);
  
    const handleImageChangePage = (e) => {
      const selectedImage = e.target.files[0];
      if (selectedImage) {
          const tailleMax = 10 * 1024 * 1024; 
          if (selectedImage.size <= tailleMax) {
            if (
                selectedImage.type === 'image/png' ||
                selectedImage.type === 'image/jpeg' ||
                selectedImage.type === 'image/jpg'
            ) {
                setImagePage(selectedImage);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
          } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
          }
      }
    };
  
    const handleSubmit = () => {
        setLoading(true);
        const formdata = new FormData();
        if(imagePage){
            formdata.append("image", imagePage);
        }
        formdata.append("id", (id_bureau)?id_bureau:id_club);
        formdata.append("name", titlePage);
        formdata.append("description", descriptionPage);

        fetch((id_bureau)?"/api/offices/update":"/api/offices/club/update", {
            method: "POST",
            headers: {
                token: localStorage.getItem("token")
            },
            body: formdata
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                loadData();
                if(id_bureau){
                    loadBureau(id_bureau);
                }else{
                    loadClub(id_club);
                }
            }else{

            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const [loading, setLoading] = useState(false);


    const handlePlus = (key) => {
        setListePlus(listePlus => [...listePlus, key]);
    }

    const handleMinus = (key) => {
        setListePlus(listePlus => listePlus.filter(elt => key!=elt));
    }

    return (<div className="page" id="serviceOfficePage">
        <NavBar titre="Gestion Bureaux/Clubs"/>

        <DialogLoading active={loading} setActive={setLoading} />

        <div className="information" id="information">
            <Element name="top" isDynamic={true}></Element>
            {(id_bureau||id_club)?<>
                <button onClick={handleBack} id="top-button">Retour</button>
                
                {(!(bureau||club))?<>
                    <h4 className="fakee"><Skeleton /></h4>
                    <h4 className="fakee"><Skeleton height={150}/></h4>
                </>:<>
                    <h4>{id_bureau?(bureau&&bureau.name):(club&&club.name)}</h4>
                    <img className="logo" src={(!imagePage)?(id_bureau?(bureau&&bureau.picture):(club&&club.picture)):(URL.createObjectURL(imagePage))}/>
                </>}
                <Select
                    id="demo-simple-select"
                    value={selectOption}
                    onChange={handleChange}
                >
                    <MenuItem value={"Evenement"}>Événement</MenuItem>
                    <MenuItem value={"Annonce"}>Annonce</MenuItem>
                    <MenuItem value={"Membres"}>Membres</MenuItem>
                    <MenuItem value={"Autres"}>Modification du {(id_bureau)?"bureau":"club"}</MenuItem>
                </Select>

                {(selectOption=="Annonce")?<>
                    <h5>Mettre une annonce:</h5>
                    <div class="tweet-box">
                        <input class="tweet-title" type="text" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder="Titre"/>
                        <textarea class="tweet-input" value={description} placeholder="Quoi de neuf ?" onChange={(e) => setDescription(e.target.value)}></textarea>
                        <div class="tweet-options">
                            <span class="character-count"></span>
                            <button class="tweet-button" onClick={handleAddPost}>Poster</button>
                        </div>
                    </div>

                    <div className="seperation"></div>
                    <h4 className="h4-annonce"><img src={annonceImg}/>Annonces</h4>

                    <Paper elevation={3} className="calendar-head">
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">
                        {monthName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                        </Button>
                    </Grid>
                    </Grid>
                    </Paper>

                    {(annonces!=null)?<>
                        {(annonces.length==0)?<>
                            <p>Il n'y a pas d'annonce.</p>
                        </>:<div className="annonces">
                            {annonces.map((elt, key) => {
                                const createdAt = new Date(elt.createdAt);
                                const options = {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                };

                                const dateLocaleFr = createdAt.toLocaleDateString('fr-FR', options);
                                return (((editPostId==elt.id)?<>
                                    <div key={key} class="tweet-box">
                                        <input class="tweet-title" type="text" value={titleMod} onChange={(e)=>setTitleMod(e.target.value)} placeholder="Titre"/>
                                        <textarea class="tweet-input" placeholder="Quoi de neuf ?" value={descriptionMod} onChange={(e) => setDescriptionMod(e.target.value)}></textarea>
                                        <div class="tweet-options">
                                            <span class="character-count">Modification en cours</span>
                                            <button class="edit-button" onClick={handleUpdatePost}>Sauvegarder</button>
                                            <button class="del-button" onClick={handleCancel}>Annuler</button>
                                        </div>
                                    </div>
                                </>:<>
                                    <div key={key} className="annonce">
                                        <div className="details">
                                            <h3>{elt.title}</h3>
                                            <div className="buttons">
                                                <button className="edit-button" onClick={() => handleEditPost(elt)}>Modifier</button>
                                                <button className="del-button" onClick={() => handleDel(elt.id)}>X</button>
                                            </div>
                                        </div>
                                        <p className="description">{elt.description}</p>
                                        <div className="details">
                                            <p className="poster">{elt.poster.last_name + ' ' + elt.poster.name}</p>
                                            <p className="date">{dateLocaleFr}</p>
                                        </div>
                                    </div>
                                </>)
                                );
                            })}
                        </div>}
                    </>:<>
                        <h3><Skeleton /></h3>
                        <p><Skeleton count={4}/></p>
                    </>}
                </>:(selectOption=="Evenement")?<>
                    <Container maxWidth="sm">
                        <h5>Créer un événement:</h5>
                        <TextField
                            label="Titre"
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                        />
                        <div className="text-field">
                            <p>Date de l'événement :</p>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="date"
                                value={date}
                                onChange={(e) => {setDate(e.target.value)}}
                                margin="normal"
                                min={new Date()}
                            />
                        </div>
                        <div className="text-field">
                            <p>Heure de l'événement :</p>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="time"
                                value={heure}
                                onChange={(e) => setHeure(e.target.value)}
                                margin="normal"
                            />
                        </div>
                        <div className="composant-telechargement-fichier">
                        <label className={`label-fichier ${fichierSelectionne ? 'selected' : ''}`}>
                            <input
                            type="file"
                            className="input-fichier"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleChangementFichier}
                            />
                            <span className="texte-label-fichier">
                            {fichierSelectionne ? 'Fichier sélectionné' : 'Choisissez un fichier'}
                            </span>
                        </label>
                        {fichierSelectionne && (
                            <div>
                            <img
                                src={fichierSelectionne?URL.createObjectURL(fichierSelectionne):null}
                                alt="Aperçu"
                                className="apercu-image"
                            />
                            </div>
                        )}
                        </div>
                        <Box mt={2}>
                            <Button variant="contained" color="primary" onClick={handleAddEvent}>
                            Ajouter
                            </Button>
                        </Box>
                    </Container>

                    <div className="seperation"></div>
                    <h4 className="h4-annonce"><img src={calendarImg}/>Événements</h4>

                    <Paper elevation={3} className="calendar-head">
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">
                        {monthName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                        </Button>
                    </Grid>
                    </Grid>
                    </Paper>

                    <div className="events">
                        {(events == null) ? (
                            <div className="event">
                                <h1><Skeleton /></h1>
                                <Skeleton height={200} />
                                <p><Skeleton count={5} /></p>
                            </div>
                        ) : ((events.length === 0) ? (
                            <p>Il n'y a pas encore d'événement prévu...</p>
                        ) : (
                            events.map((elt, key) => {

                                const long_description = elt.description.split("\n").filter(elt => elt!="").length > 3;
                                return ((elt.id==editEventId)?<>
                                <div key={key} className="event update">
                                    <div className="head">
                                        <TextField
                                            label="Titre"
                                            variant="outlined"
                                            fullWidth
                                            value={titleMod}
                                            onChange={(e) => setTitleMod(e.target.value)}
                                            margin="normal"
                                        />
                                        <button className="del-button" onClick={() => handleCancelEvent()}>Annuler</button>
                                    </div>
                                    <div className="event-image">
                                        <img src={(fichierSelectionneMod)?URL.createObjectURL(fichierSelectionneMod):elt.picture} alt={elt.title} />
                                    </div>
                                    <div className="event-details"> 
                                        <TextField
                                            label="Description"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={descriptionMod}
                                            onChange={(e) => setDescriptionMod(e.target.value)}
                                            margin="normal"
                                        />
                                        <div className="date-details">
                                            <div className="text-field">
                                                <p>Date de l'événement :</p>
                                                <TextField
                                                    variant="outlined"
                                                    type="date"
                                                    value={dateMod}
                                                    onChange={(e) => setDateMod(e.target.value)}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="text-field">
                                                <p>Heure de l'événement :</p>
                                                <TextField
                                                    variant="outlined"
                                                    type="time"
                                                    value={heureMod}
                                                    onChange={(e) => setHeureMod(e.target.value)}
                                                    margin="normal"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-update">
                                        <div className="composant-telechargement-fichier">
                                        <label className={`label-fichier ${fichierSelectionneMod ? 'selected' : ''}`}>
                                            <input
                                            type="file"
                                            className="input-fichier"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={handleChangementFichierMod}
                                            />
                                            <span className="texte-label-fichier">
                                            {fichierSelectionneMod ? 'Nouvelle image selectionné' : 'Modifier l\'image'}
                                            </span>
                                        </label>
                                        </div>
                                        <button className="edit-button" onClick={handleSendEditEvent}>Sauvegarder</button>
                                    </div>
                                </div>
                            </>:<>
                                <div key={key} className="event">
                                    <div className="head">
                                        <h1>{elt.title}</h1>
                                        {/* <div className="entity-info">
                                            <img src={elt.entity.picture} alt={elt.entity.name} className="entity-logo" />
                                            <p className="entity-name">{elt.entity.name}</p>
                                        </div> */}

                                        <div className="event-update">
                                            <button className="edit-button" onClick={() => handleEditEvent(elt)}>Modifier</button>
                                            <button className="del-button" onClick={() => handleDelEvent(elt.id)}>X</button>
                                        </div>
                                    </div>
                                    <div className="event-image">
                                        <img src={elt.picture} alt={elt.title} />
                                    </div>
                                    <div className="event-details"> 
                                        <p className="event-description">{(long_description&&!(listePlus.indexOf(key)!=-1))?((elt.description.split("\n").filter((elt, k) => 0 <= k && k < 3).join("\n"))):elt.description}{long_description&&<strong className="description-plus"><button onClick={(!(listePlus.indexOf(key)!=-1))?() => handlePlus(key):()=> handleMinus(key)}>... {(!(listePlus.indexOf(key)!=-1))?"plus":"moins"}</button></strong>}</p>
                                        <div className="date-details">
                                            <p className="event-date">Date: {new Date(elt.date).toLocaleString('fr-FR', { dateStyle: 'long', timeStyle: 'short' })}</p>
                                            <p>De <span>{elt.poster.name+" "+elt.poster.last_name}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </>)
                            })
                        ))}
                    </div>

                </>:(selectOption=="Membres")?<>
                    <div className="member-page">
                        {(id_bureau||id_club)&&<>
                            <label>Membres:</label>
                            {(bureau||club).members&&(bureau||club).members.length==0?<>
                                <p>Pour l'instant, il n'y aucun membre.</p>
                            </>:<>
                                <ul>
                                    {(bureau||club).members&&(bureau||club).members.map((elt,key) => 
                                        <li key={key}>{elt.name+" "+elt.last_name}<button className="del-button" onClick={e => handleDelMember(elt.id)}>X</button></li>
                                    )}
                                </ul>
                            </>}
                        </>}

                        <p>En ajouter ?</p>
                        <div className="search">
                            <SearchIcon className="icon" />
                            <TextField
                                placeholder="Rechercher dans les élèves"
                                variant="outlined"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                fullWidth
                                size="small"
                                InputProps={{
                                style: {
                                    padding: '8px 0',
                                },
                                }}
                            />
                        </div>
                        {members&&<ul>
                            {members.filter((elt, key) => ((elt.last_name+" "+elt.name).toLowerCase().includes(search.toLowerCase()) || (elt.name+" "+elt.last_name).toLowerCase().includes(search.toLowerCase()))).map((elt,key) => 
                                <li key={key}>{elt.name+" "+elt.last_name}<button className="add-button" onClick={e => handleAddMember(elt.id)} >+</button></li>
                            )}
                        </ul>}
                    </div>
                    <div className="bottom"></div>
                </>:<>
                    <div className="modif-page">
                        <p>Modification de la page</p>
                        <form>
                        <TextField
                            label="Titre"
                            fullWidth
                            variant="outlined"
                            value={titlePage}
                            onChange={(e) => setTitlePage(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={descriptionPage}
                            onChange={(e) => setDescriptionPage(e.target.value)}
                            margin="normal"
                        />
                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleImageChangePage}
                            style={{ display: 'none' }}
                            id="image-upload"
                        />
                        <label htmlFor="image-upload">
                            <Button variant="contained" component="span">
                            {!imagePage?"Choisir une image":"Modifier l'image"}
                            </Button>
                        </label>
                        {imagePage && <p>Image sélectionnée: {imagePage.name}</p>}
                        <Box mt={2}>
                            <Button className="send-button" variant="contained" color="primary" onClick={handleSubmit}>
                            Enregistrer les modifications
                            </Button>
                        </Box>
                        </form>
                    </div>
                </>}
                
            </>:<>
                <h4>Veuillez choisir le Bureau/Club que vous souhaitez gérer:</h4>
                <div className="search">
                    <SearchIcon className="icon" />
                    <TextField
                        placeholder="Rechercher dans les bureaux/clubs"
                        variant="outlined"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        fullWidth
                        size="small"
                        InputProps={{
                        style: {
                            padding: '8px 0',
                        },
                        }}
                    />
                </div>
                <div className="offices">
                    {data?<>
                        <h5>Bureaux:</h5>
                        {data.offices&&data.offices.length!=0?<>
                            {data.offices.filter((elt) => elt.name.toLowerCase().includes(search.toLocaleLowerCase())).map((elt, key) => {
                                return (<div key={key} className="element">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                        </div>
                                        <Link to={"?id="+elt.id} onClick={() => {}}>
                                            <img alt="logo" src={elt.picture} />
                                        </Link>
                                </div>);
                            })}
                        </>:<>
                            <p>Vous n'appartenez à aucun bureau...</p>
                        </>}
                        {user&&!user.is_admin&&<>

                            <h5>Clubs:</h5>
                            {data.clubs&&data.clubs.length!=0?<>
                                {data.clubs.filter((elt) => elt.name.toLowerCase().includes(search.toLocaleLowerCase())).map((elt, key) => {
                                    return (<div key={key} className="element">
                                            <div className="top">
                                                <h3>{elt.name}</h3>
                                            </div>
                                            <Link to={"?id_club="+elt.id} onClick={() => {}}>
                                                <img alt="logo" src={elt.picture} />
                                            </Link>
                                    </div>);
                                })}
                            </>:<>
                                <p>Vous n'appartenez à aucun club...</p>
                            </>}

                        </>}
                        
                        <h5>Clubs sous vos bureaux:</h5>
                        {data.clubs_indirect&&data.clubs_indirect.length!=0?<>
                            {data.clubs_indirect.filter((elt) => elt.name.toLowerCase().includes(search.toLocaleLowerCase())).map((elt, key) => {
                                return (<div key={key} className="element">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                        </div>
                                        <Link to={"?id_club="+elt.id} onClick={() => {}}>
                                            <img alt="logo" src={elt.picture} />
                                        </Link>
                                </div>);
                            })}
                        </>:<>
                            <p>Vous n'appartenez à aucun club sous vos bureaux...</p>
                        </>}
                    </>:<>
                        <h5>Bureaux:</h5>
                        <div className="element">
                                <div className="top">
                                    <h3><Skeleton /></h3>
                                </div>
                                
                                <div className="top">
                                    <h3><Skeleton height={70} /></h3>
                                </div>
                        </div>
                        <h5>Clubs:</h5>
                        <div className="element">
                                <div className="top">
                                    <h3><Skeleton /></h3>
                                </div>
                                
                                <div className="top">
                                    <h3><Skeleton height={70} /></h3>
                                </div>
                        </div>
                    </>}
                </div>
            </>}
            <div className="bottom"></div>
        </div>
        <Menu />
    </div>);
};