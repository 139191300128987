import '../css/Menu.css';

import grilleImg from '../images/grille.png';
import calendarImg from '../images/calendrier_menu.png';
import userImg from '../images/utilisateur.png';


import { Link } from 'react-router-dom';
import React from 'react';


const Menu = (props) => {
    const {psn} = props;

    return (<div id="menu" style={psn?{backgroundColor:"#006FC0"}:{}}>
        <ul>
            <li><Link to="/"><img alt="grille" src={grilleImg}/></Link></li>
            <li><Link to="/events"><img alt="calendrier" src={calendarImg}/></Link></li>
            <li><Link to="/me"><img alt="utilisateur" src={userImg}/></Link></li>
        </ul>
    </div>);
}

export default Menu;