import NavBar from '../components/Nav';
import React, {useState, useEffect} from 'react';

import {Link, useLocation, useNavigate, Navigate} from 'react-router-dom';

import { NotificationManager } from 'react-notifications';

import DialogLoading from '../utils/DialogLoading';

import logo from '../images/icon.png';
import arrowImg from '../images/left-arrow.png';

const LoginPage = (props) => {

    const {setUser, user} = props;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    if(user){
        return (<Navigate to="/" replace/>);
    }

    const handleKey = (e) => {
        if(e.key == "Enter"){
            handleSend();
        }
    }

    const handleSend = () => {
        setLoading(true);
        fetch("/api/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password,
                first_name:prenom,
                last_name:nom,
                confirm_password: confirmationPassword
            })
        }).then(async rep => {
            setLoading(false);
            const data = await rep.json();
            if(rep.status === 200){
                if(data.message){
                    NotificationManager.success(data.message);
                }


                //on retourne sur la page d'accueil
                navigate("/login");
            }else{
                if(data.data&&data.data.error){
                    NotificationManager.warning(data.data.error);
                }else if(data.message){
                    NotificationManager.warning(data.message);
                }
            }
        }).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };


    const width = window.screen.width;
    const height = window.screen.height/2;

    return (<div className="page" id="signUpPage">
        <DialogLoading active={loading} setActive={setLoading} />
        <div className="topdesign">
            <svg  class="svg">
                <path id="curve" fill="#df2222" d={`M ${width} ${height/5.5} Q ${width/1.1} ${height/5} ${width/1.3} ${height/5} Q ${width/2.5} ${height/5.5} ${width/4} ${height/3} Q ${width/5} ${height/2.6} 0 ${height/1.9} L 0 0 L ${width} 0 Z`}/>
            </svg>
        </div>
        <div className='backButton'>
            <Link to="/login"><img alt="<" src={arrowImg}/></Link>
        </div>
        <div className="form">
            <div className="head">
                <img alt="CDE" src={logo}/>
                <h3>Inscrivez-vous dès maintenant !</h3>
            </div>
            
            <div className="middle">
                <div>
                    <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe"/>
                    <input type="password" onChange={(e) => setConfirmationPassword(e.target.value)} placeholder="Confirmation du mot de passe"/>
                    <input type="text" onChange={(e) => setNom(e.target.value)} placeholder="Nom" />
                    <input type="text"onKeyDown={handleKey} onChange={(e) => setPrenom(e.target.value)} placeholder="Prenom" />
                </div>

                <button onClick={handleSend}>></button>
            </div>

            <div className="foot">
                <Link to="/login">Vous êtes déjà inscrit ?</Link>
                {/* <Link to="/forget">Mot de passe oublié ?</Link> */}
            </div>
        </div>
        <div className="bottomdesign">
            <svg  class="svg">
                    <path id="curve" fill="#1b1919" d={`M ${width} ${height/5.5} Q ${width/1.1} ${height/5} ${width/1.3} ${height/5} Q ${width/2.5} ${height/5.5} ${width/4} ${height/3} Q ${width/5} ${height/2.6} 0 ${height/1.9} L 0 0 L ${width} 0 Z`}/>
            </svg>
        </div>
    </div>)
}

export default LoginPage;