import React, {useState, useEffect} from 'react';

import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import { NotificationManager } from 'react-notifications';
import backImg from '../images/left-arrow.png';

import { Link, useSearchParams} from 'react-router-dom';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { handleResponse } from '../utils/utils';

import {scroller as scroll, scrollSpy, Element, Link as LinkScroll} from 'react-scroll';


export default (props) => {
    const {user, setUser} = props;

    const [useParam, ] = useSearchParams();

    const [subscriptions, setSubscriptions] = useState(null);
    const [listePlus, setListePlus] = useState([]);

    const [idParam, setIdParam] = useState(useParam.get("id"));

    const loadSubscriptions = () => {
        if(localStorage.getItem("token")){
            fetch("/api/me/notifications", {
                method: "GET", 
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setSubscriptions(data.data);
                    }
                }else{

                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    useEffect(() => {
        loadSubscriptions();
    }, []);


    const handlePlus = (key) => {
        setListePlus(listePlus => [...listePlus, key]);
    }

    const handleMinus = (key) => {
        setListePlus(listePlus => listePlus.filter(elt => key!=elt));
    }

    return (<div className="page" id="abonnementsPage">
        <NavBar titre="Abonnements"/>
        <div className="information" id="information">
            {/* <h4>Actualités basé sur vos abonnements :</h4> */}
            {(localStorage.getItem("token"))?<>
                {(subscriptions==null)?<>
                    <div className="subscriptions">
                        <div className="element">
                            <h1><Skeleton /></h1>

                            <p><Skeleton count={2}/></p>
                        </div>
                    </div>
                </>:((subscriptions.length==0)?<>
                    <p>Il ne se passe rien pour l'instant...</p>
                </>:<>
                    {/* <p>Affichage des activités</p> */}
                    <div className="annonces">
                        {subscriptions.sort((a, b) => {
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);

                            // Trie du plus jeune au plus vieux
                            return dateB - dateA;
                        }).map((elt, key) => {
                            const createdAt = new Date(elt.createdAt);
                            const options = {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            };

                            const dateLocaleFr = createdAt.toLocaleDateString('fr-FR', options);
                            const long_description = elt.description.split("\n").filter(elt => elt!="").length > 3;
                            return (
                                (!(elt.typeElement&&elt.typeElement=="event"))?
                                <div key={key} className={"annonce"+((idParam==elt.id)?" focus":"")}>
                                    <Element name={elt.id}></Element>
                                    <a href={((elt.entity.is_je)?"/services/psn?":`/services/club?id${(elt.entity.type=="club")?"_club=":"="}${elt.entity.id}&`)+`sub_id=${elt.id}&date=${elt.createdAt}`} className="annonce-link">
                                        <div className="details">
                                            <h3>{elt.title}</h3>
                                            {elt.entity && (
                                                <div className="club-details">
                                                    <img src={elt.entity.picture} alt={elt.entity.name} className="club-logo" />
                                                    <p>{elt.entity.name}</p>
                                                </div>
                                            )}
                                        </div>
                                        <p className="description">{elt.description}</p>
                                        <div className="details">
                                            <p className="date">Publié le {dateLocaleFr}</p>
                                        </div>
                                    </a>
                                </div>:<div key={key} className={"element"+((elt.id==idParam)?" focus":"")}>
                                    <Element name={elt.id} ></Element>
                                    <div className="head">
                                        <h1>{elt.title}</h1>
                                        <div className="entity-info">
                                            <img src={elt.entity.picture} alt={elt.entity.name} className="entity-logo" />
                                            <p className="entity-name">{elt.entity.name}</p>
                                        </div>
                                    </div>
                                    <div className="event-image">
                                        <img src={elt.picture} alt={elt.title} />
                                    </div>
                                    <div className="event-details"> 
                                        <p className="event-description">{(long_description&&!(listePlus.indexOf(key)!=-1))?((elt.description.split("\n").filter((elt, k) => 0 <= k && k < 3).join("\n"))):elt.description}{long_description&&<strong className="description-plus"><button onClick={(!(listePlus.indexOf(key)!=-1))?() => handlePlus(key):()=> handleMinus(key)}>... {(!(listePlus.indexOf(key)!=-1))?"plus":"moins"}</button></strong>}</p>
                                        <div className="date-details">
                                            <p className="event-date">Date: {new Date(elt.date).toLocaleString('fr-FR', { dateStyle: 'long', timeStyle: 'short' })}</p>
                                            {/* <p>De <span>{elt.poster.name+" "+elt.poster.last_name}</span></p> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="bottom"></div>
                </>)}
                
            </>:<>
                <p className="title">Pour profiter des notifications, il faut vous inscrire !</p>
                <Link className="link-not" to="/signup">S'inscrire pour de bon</Link>
            </>}
        </div>
        {
            setTimeout(() => {
                scroll.scrollTo(idParam, {
                    offset: -30,
                    containerId: "information"
                })
            }, 300)
        }
        <Menu />
    </div>);
};