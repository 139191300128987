import React, {useState, useEffect} from 'react';

import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import { handleResponse } from '../utils/utils';

import plusImg from '../images/plus.png';
import minusImg from '../images/close.png';
import checkImg from '../images/check-mark.png';
import annonceImg from '../images/megaphone.png';
import calendarImg from '../images/calendrier_menu.png';
import bellImg from '../images/bell.png';
import bellOKImg from '../images/bell_ok.png';


import DialogLoading from '../utils/DialogLoading';

import { NotificationManager } from 'react-notifications';

import { ButtonGroup, Button, Select, MenuItem, Container, TextField, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {scroller as scroll, scrollSpy, Element, Link as LinkScroll} from 'react-scroll';



import {Typography, Grid, Paper } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


export default (props) => {
    const {user, setUser} = props;

    const [useParam, ] = useSearchParams();
    const navigate = useNavigate();

    const [isManagePSN, setIsManagePSN] = useState(false);

    const [follow, setFollow] = useState(null);
    const [bell, setBell] = useState(null);

    const [club, setClub] = useState(null);
    const [id_club, setIdClub] = useState(null);
    const id_bureau = false;
    
    const [annonces, setAnnonces] = useState(null);

    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const previousMonth = () => {
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        if(club){
            loadAnnonces(club.id, "club", prevMonth);
            loadEvents(club.id, "club", prevMonth);
        }
        setCurrentMonth(prevMonth);
    };

    const nextMonth = () => {
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        if(club){
            loadAnnonces(club.id, "club", nextMonth);
            loadEvents(club.id, "club", nextMonth);
        }
        setCurrentMonth(nextMonth);
    };

    let monthName = new Intl.DateTimeFormat('fr', { month: 'long' }).format(currentMonth);
    monthName = monthName[0].toUpperCase()+monthName.substring(1);

    
    const loadAnnonces = (id, type, month) => {
        fetch("/api/offices/posts?id="+encodeURIComponent(id)+"&type="+type+"&month="+encodeURIComponent(month), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setAnnonces(data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }


    const sub_id = useParam.get("sub_id");

    //pour que ça fonctionne
    const loadBureau = (id) => {

    };

    const checkFollow = (id, type) => {
        setFollow(null);
        checkBell(id, type)
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/one?id="+encodeURIComponent(id)+"&type="+type, {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setFollow(data.data.follow);
                    }
                }else{
                    setFollow(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const checkBell = (id, type) => {
        setBell(null);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/notif/one?id="+encodeURIComponent(id)+"&type="+type, {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setBell(data.data.bell);
                    }
                }else{
                    setBell(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const loadClub = (id) => {
        fetch("/api/offices/club/one?id="+encodeURIComponent(id), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data&&data.data){
                setClub(data.data);
                checkFollow(data.data.id, "club");
                setTitlePage(data.data.name);
                setDescriptionPage(data.data.description);
            }else{
                setClub(null);
                navigate("");
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const handleFollow = (id, type) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    state: !follow,
                    id,
                    type
                })
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    checkFollow(id, type);
                }else{

                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }else{
            NotificationManager.info("Vous ne pouvez pas suivre de bureau ou de club si vous n'avez pas de compte...");
        }
    }
    
    const handleBack = () => {
        navigate("");
    }


    const handleClick = (value) => {
        setIsManagePSN(value);
        navigate("");
        const newMonth = new Date();
        setCurrentMonth(newMonth);
        if(id_bureau){
            loadAnnonces(id_bureau, "office", newMonth);
            loadEvents(id_bureau, "office", newMonth);
        }else{
            loadAnnonces(id_club, "club", newMonth);
            loadEvents(id_club, "club", newMonth);
        }
    };

    const loadIdPSN = () => {
        fetch("/api/psn/id", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    const idClub = data.data;
                    setIdClub(idClub);
                    loadClub(idClub);
                    loadAnnonces(idClub, "club", currentMonth);
                    loadEvents(idClub, "club", currentMonth);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const handleBell = (id, type) => {
        //pour éviter d'être bloqué sur l'elemet sub
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/notif/update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    state: !bell,
                    id,
                    type
                })
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    checkBell(id, type);
                }else{

                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }else{
            NotificationManager.info("Vous ne pouvez pas activer les notifications de bureau ou de club si vous n'avez pas de compte...");
        }
    }

    useEffect(() => {
        loadIdPSN();
    }, []);

    const visitorContent = (<div className="mainClub">
        {club?<>
            <div className="top">
                <h3>{club.name}</h3>

                {(club&&follow!=null&&follow&&bell!=null)?<>
                    <button title="Pour activer les notifications" onClick={() => handleBell(club.id, "club")} className={"bell-button"+((bell)?" activate":"")}><img src={(bell)?bellOKImg:bellImg}/></button>
                </>:((localStorage.getItem("token")&&follow)&&<>
                    <h3 className="bell-button fakebutton"><Skeleton /></h3>
                </>)}


                {(club&&follow!=null)?<>
                    <button onClick={() => handleFollow(club.id, "club")} className={"follow-button"+((follow)?" activate-follow":"")}><img src={checkImg}/>{(follow)?"Suivi":"Suivre"}</button>
                </>:(localStorage.getItem("token"))?<>
                    <h3 className="fakebutton"><Skeleton /></h3>
                </>:<>
                    <button onClick={() => NotificationManager.info("Vous devez vous connecter pour suivre un bureau/club")} className="follow-button"><img src={checkImg}/>Suivre</button>
                </>}
            </div>

            <img src={club.picture} />

            <div className="description-bureau">
                <label>Description de la JE:</label>
                <pre>{club.description}</pre>
                <label>Membres:</label>
                {club.members&&club.members.length==0?<>
                    <p>Pour l'instant, il n'y aucun membre.</p>
                </>:<>
                    <ul>
                        {club.members&&club.members.map((elt,key) => 
                            <li key={key}>{elt.name+" "+elt.last_name}</li>
                        )}
                    </ul>
                </>}
            </div>
            <div className="seperation"></div>
            

            <div className="seperation"></div>
            <h4 id="annonces" className="h4-annonce"><img src={annonceImg}/>Annonces</h4>
            
            <Paper elevation={3} className="calendar-head">
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="h6">
                {monthName}
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                </Button>
            </Grid>
            </Grid>
            </Paper>

            {(annonces!=null)?<>
                {(annonces.length==0)?<>
                    <p>Il n'y a pas d'annonce.</p>
                </>:<div className="annonces">
                    {annonces.map((elt, key) => {
                        let { title, description, type, poster, createdAt } = elt;
                        createdAt = new Date(createdAt);
                        const options = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        };

                        if(key==annonces.length-1){
                            scroll.scrollTo(sub_id, {
                                offset: -30,
                                containerId: "information",
                            });
                        }

                        const dateLocaleFr = createdAt.toLocaleDateString('fr-FR', options);
                        return (
                        <div key={key} className={"annonce"+((sub_id&&elt.id==sub_id)?" selected":"")}>
                            <Element name={elt.id} isDynamic={true}></Element>
                            <h3>{title}</h3>
                            <p className="description">{description}</p>
                            <div className="details">
                            <p className="poster">{poster.last_name + ' ' + poster.name}</p>
                            <p className="date">{dateLocaleFr}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>}
            </>:<>
                <h3><Skeleton /></h3>
                <p><Skeleton count={4}/></p>
            </>}
        </>:<>
            <h3 className="fakee"><Skeleton /></h3>
            <h3 className="fakee"><Skeleton height={170} /></h3>
            <div className="description-bureau">
                <label>Description du club:</label>
                <p><Skeleton count={3}/></p>
                <label>Membres:</label>
                <p><Skeleton count={1}/></p>
            </div>
            <div className="seperation"></div>
        </>}
    </div>);

    //admin content
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [heure, setHeure] = useState(null);

    const [editPostId, setEditPostId] = useState(null);
    const [editEventId, setEditEventId] = useState(null);
    const [dateMod, setDateMod] = useState('');
    const [heureMod, setHeureMod] = useState(null);

    const handleAddPost = () => {
        setLoading(true);
        fetch("/api/me/addPost", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: (id_bureau)?id_bureau:id_club,
                title,
                description,
                type: (id_bureau)?"office":"club"
            })
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadAnnonces(id_bureau, "office", currentMonth);
                }else{
                    loadAnnonces(id_club, "club", currentMonth);
                }
                setTitle("");
                setDescription("");
            }else{

            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const handleAddEvent = () => { 
        const formdata = new FormData();
        setLoading(true);
        formdata.append("id", (id_bureau)?id_bureau:id_club);
        formdata.append("title", title);
        formdata.append("description", description);
        formdata.append("type", (id_bureau)?"office":"club");
        formdata.append("date", date);
        formdata.append("image", fichierSelectionne);
        
        formdata.append("hour", heure);
        fetch("/api/me/addEvent", {
            method: "POST",
            headers: {
                token: localStorage.getItem("token")
            },
            body: formdata
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadEvents(id_bureau, "office", currentMonth);
                }else{
                    loadEvents(id_club, "club", currentMonth);
                }
                setTitle("");
                setDescription("");
                setDate("");
                setFichierSelectionne(null);
                setHeure(null);
            }else{

            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }


    const [events, setEvents] = useState(null);
    const loadEvents = (id, type, month) => {
        fetch("/api/offices/events?id="+encodeURIComponent(id)+"&type="+type+"&date="+encodeURIComponent(month), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setEvents(data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }


    const [selectOption, setSelectOption] = useState("Evenement");

    const [titleMod, setTitleMod] = useState("");
    const [descriptionMod, setDescriptionMod] = useState("");

    const [members, setMembers] = useState(null);
    const loadMembers = () => {
        fetch("/api/members/all", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setMembers(data.data);
                }
            }else{
                setMembers(null);
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        });
    };

    const [fichierSelectionne, setFichierSelectionne] = useState(null);
    const [fichierSelectionneMod, setFichierSelectionneMod] = useState(null);

    const handleChangementFichier = (e) => {
        const fichierSelectionne = e.target.files[0];
        if (fichierSelectionne) {
            // Vérifiez la taille du fichier (10 Mo maximum)
            const tailleMax = 10 * 1024 * 1024; // 10 Mo en octets
            if (fichierSelectionne.size <= tailleMax) {
            // Vérifiez le type de fichier avant de l'accepter
            if (
                fichierSelectionne.type === 'image/png' ||
                fichierSelectionne.type === 'image/jpeg' ||
                fichierSelectionne.type === 'image/jpg'
            ) {
                setFichierSelectionne(fichierSelectionne);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
            } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
            }
        }
    };

    const handleChangementFichierMod = (e) => {
        const fichierSelectionne = e.target.files[0];
        if (fichierSelectionne) {
            // Vérifiez la taille du fichier (10 Mo maximum)
            const tailleMax = 10 * 1024 * 1024; // 10 Mo en octets
            if (fichierSelectionne.size <= tailleMax) {
            // Vérifiez le type de fichier avant de l'accepter
            if (
                fichierSelectionne.type === 'image/png' ||
                fichierSelectionne.type === 'image/jpeg' ||
                fichierSelectionne.type === 'image/jpg'
            ) {
                setFichierSelectionneMod(fichierSelectionne);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
            } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
            }
        }
    };


    
    const handleChange = (e) => {
        setSelectOption(e.target.value);
        setImagePage(null);
        setTitlePage(club&&club.name);
        setDescriptionPage(club&&club.description);
        loadMembers();
        const newMonth = new Date();
        setCurrentMonth(newMonth);
        if(id_bureau){
            loadAnnonces(id_bureau, "office", newMonth);
            loadEvents(id_bureau, "office", newMonth);
        }else{
            loadAnnonces(id_club, "club", newMonth);
            loadEvents(id_club, "club", newMonth);
        }
    }

    const handleCancel = () => {
        setTitleMod("");
        setDescriptionMod("");
        setEditPostId(null);
    }


    const handleUpdatePost = () => {
        setLoading(true);
        fetch(`/api/me/editPost/${editPostId}`, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                title: titleMod,
                description: descriptionMod
            })
        })
        .then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if (data) {
                if(id_bureau){
                    loadAnnonces(id_bureau, "office", currentMonth);
                }else{
                    loadAnnonces(id_club, "club", currentMonth);
                }
                handleCancel();
            } else {
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const handleDel = (postId) => {
        setLoading(true);
        fetch(`/api/me/deletePost/${postId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token")
          }
        })
          .then((res) => handleResponse(res, true, (data) => {
            setLoading(false);
            if (data) {
                if(id_bureau){
                    loadAnnonces(id_bureau, "office", currentMonth);
                }else{
                    loadAnnonces(id_club, "club", currentMonth);
                }
            } else {
            }
          }))
          .catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
      };

    const handleEditPost = (post) => {
        setTitleMod(post.title);
        setDescriptionMod(post.description);
        setEditPostId(post.id);
        setEditEventId(null);
    }


    const handleDelEvent = (id) => {
        setLoading(true);
        fetch("/api/me/deleteEvent/"+id, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadEvents(id_bureau, "office", currentMonth);
                }else{
                    loadEvents(id_club, "club", currentMonth);
                }
            }else{

            }
        }))
        .catch(e => {
            setLoading(false);
          NotificationManager.warning("Il y a eu une erreur côté serveur...");
      })
    }

    const handleEditEvent = (elt) => {
        setTitleMod(elt.title);
        setDescriptionMod(elt.description);

        const newdate = new Date(elt.date);
        setDateMod(newdate.toISOString().split("T")[0]);
        const newFormatHour = newdate.getHours().toString().padStart(2, '0')+":"+newdate.getMinutes().toString().padStart(2, '0');
        setHeureMod(newFormatHour);

        setEditPostId(null);
        setEditEventId(elt.id);
    }

    const handleCancelEvent = () => {
        setTitleMod("");
        setDescriptionMod("");
        setEditEventId(null);
        setDateMod("");
        setHeureMod("");
        setFichierSelectionneMod(null);
    }

    const handleSendEditEvent = () => {
        const formdata = new FormData();
        setLoading(true);

        formdata.append("title", titleMod);
        formdata.append("description", descriptionMod);
        
        let newdate = new Date(dateMod);
        if(heureMod){
            newdate.setHours(parseInt(heureMod.split(":")[0]));
            newdate.setMinutes(parseInt(heureMod.split(":")[1]));
        }
        formdata.append("date", newdate);
        if(fichierSelectionneMod){
            formdata.append("image", fichierSelectionneMod);
        }
        fetch(`/api/me/editEvent/${editEventId}`, {
            method: "POST", 
            headers: {
                token: localStorage.getItem("token")
            },
            body: formdata
        })
        .then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if (data) {
                if(id_bureau){
                    loadEvents(id_bureau, "office", currentMonth);
                }else{
                    loadEvents(id_club, "club", currentMonth);
                }
                handleCancelEvent();
            } else {
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }
    
    const handleDelMember = (id) => {
        fetch("/api/me/delMember/"+id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: ((id_bureau)?id_bureau:id_club),
                type: ((id_bureau)?"office":"club")
            })
        }).then(res => handleResponse(res, true, (data) => {
            if(data){
                if(id_bureau){
                    loadBureau(id_bureau);
                }else{
                    loadClub(id_club);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }
    const [search, setSearch] = useState("");

    const handleAddMember = (id) => {
        fetch("/api/me/addMember/"+id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                id: ((id_bureau)?id_bureau:id_club),
                type: ((id_bureau)?"office":"club")
            })
        }).then(res => handleResponse(res, true, (data) => {
            if(data){
                if(id_bureau){
                    loadBureau(id_bureau);
                }else{
                    loadClub(id_club);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const [titlePage, setTitlePage] = useState('');
    const [descriptionPage, setDescriptionPage] = useState('');
    const [imagePage, setImagePage] = useState(null);
  
    const handleImageChangePage = (e) => {
      const selectedImage = e.target.files[0];
      if (selectedImage) {
          const tailleMax = 10 * 1024 * 1024; 
          if (selectedImage.size <= tailleMax) {
            if (
                selectedImage.type === 'image/png' ||
                selectedImage.type === 'image/jpeg' ||
                selectedImage.type === 'image/jpg'
            ) {
                setImagePage(selectedImage);
            } else {
                NotificationManager.warning('Veuillez sélectionner un fichier au format PNG, JPEG ou JPG.');
            }
          } else {
            NotificationManager.warning('Le fichier est trop volumineux. Veuillez sélectionner un fichier de 10 Mo maximum.');
          }
      }
    };
  
    const handleSubmit = () => {
        const formdata = new FormData();
        setLoading(true);
        if(imagePage){
            formdata.append("image", imagePage);
        }
        formdata.append("id", (id_bureau)?id_bureau:id_club);
        formdata.append("name", titlePage);
        formdata.append("description", descriptionPage);

        fetch((id_bureau)?"/api/offices/update":"/api/offices/club/update", {
            method: "POST",
            headers: {
                token: localStorage.getItem("token")
            },
            body: formdata
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                if(id_bureau){
                    loadBureau(id_bureau);
                }else{
                    loadClub(id_club);
                }
            }else{

            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const adminContent = (<>
        <Element name="top" isDynamic={true}></Element>
        
        {(!(club))?<>
            <h4 className="fakee"><Skeleton /></h4>
            <h4 className="fakee"><Skeleton height={150}/></h4>
        </>:<>
            <h4>{(club&&club.name)}</h4>
            <img className="logo" src={(!imagePage)?((club&&club.picture)):(URL.createObjectURL(imagePage))}/>
        </>}
        <Select
            value={selectOption}
            onChange={handleChange}
        >
            <MenuItem value={"Evenement"}>Événement</MenuItem>
            <MenuItem value={"Annonce"}>Annonce</MenuItem>
            <MenuItem value={"Membres"}>Membres</MenuItem>
            <MenuItem value={"Autres"}>Modification du club</MenuItem>
        </Select>

        {(selectOption=="Annonce")?<>
            <h5>Mettre une annonce:</h5>
            <div class="tweet-box">
                <input class="tweet-title" type="text" onChange={(e)=>setTitle(e.target.value)} placeholder="Titre"/>
                <textarea class="tweet-input" placeholder="Quoi de neuf ?" onChange={(e) => setDescription(e.target.value)}></textarea>
                <div class="tweet-options">
                    <span class="character-count"></span>
                    <button class="tweet-button" onClick={handleAddPost}>Poster</button>
                </div>
            </div>

            <div className="seperation"></div>

            <h4 className="h4-annonce"><img src={annonceImg}/>Annonces</h4>

            <Paper elevation={3} className="calendar-head">
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="h6">
                {monthName}
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                </Button>
            </Grid>
            </Grid>
            </Paper>

            {(annonces!=null)?<>
                {(annonces.length==0)?<>
                    <p>Il n'y a pas d'annonce.</p>
                </>:<div className="annonces">
                    {annonces.map((elt, key) => {
                        const createdAt = new Date(elt.createdAt);
                        const options = {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };

                        const dateLocaleFr = createdAt.toLocaleDateString('fr-FR', options);
                        return (((editPostId==elt.id)?<>
                            <div key={key} class="tweet-box">
                                <input class="tweet-title" type="text" value={titleMod} onChange={(e)=>setTitleMod(e.target.value)} placeholder="Titre"/>
                                <textarea class="tweet-input" placeholder="Quoi de neuf ?" value={descriptionMod} onChange={(e) => setDescriptionMod(e.target.value)}></textarea>
                                <div class="tweet-options">
                                    <span class="character-count">Modification en cours</span>
                                    <button class="edit-button" onClick={handleUpdatePost}>Sauvegarder</button>
                                    <button class="del-button" onClick={handleCancel}>Annuler</button>
                                </div>
                            </div>
                        </>:<>
                            <div key={key} className="annonce">
                                <div className="details">
                                    <h3>{elt.title}</h3>
                                    <div className="buttons">
                                        <button className="edit-button" onClick={() => handleEditPost(elt)}>Modifier</button>
                                        <button className="del-button" onClick={() => handleDel(elt.id)}>X</button>
                                    </div>
                                </div>
                                <p className="description">{elt.description}</p>
                                <div className="details">
                                    <p className="poster">{elt.poster.last_name + ' ' + elt.poster.name}</p>
                                    <p className="date">{dateLocaleFr}</p>
                                </div>
                            </div>
                        </>)
                        );
                    })}
                </div>}
            </>:<>
                <h3><Skeleton /></h3>
                <p><Skeleton count={4}/></p>
            </>}
        </>:(selectOption=="Evenement")?<>
            <Container maxWidth="sm">
                <h5>Créer un événement:</h5>
                <TextField
                    label="Titre"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                <div className="text-field">
                    <p>Date de l'événement :</p>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="date"
                        value={date}
                        onChange={(e) => {setDate(e.target.value)}}
                        margin="normal"
                        min={new Date()}
                    />
                </div>
                <div className="text-field">
                    <p>Heure de l'événement :</p>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="time"
                        value={heure}
                        onChange={(e) => setHeure(e.target.value)}
                        margin="normal"
                    />
                </div>
                <div className="composant-telechargement-fichier">
                <label className={`label-fichier ${fichierSelectionne ? 'selected' : ''}`}>
                    <input
                    type="file"
                    className="input-fichier"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleChangementFichier}
                    />
                    <span className="texte-label-fichier">
                    {fichierSelectionne ? 'Fichier sélectionné' : 'Choisissez un fichier'}
                    </span>
                </label>
                {fichierSelectionne && (
                    <div>
                    <img
                        src={URL.createObjectURL(fichierSelectionne)}
                        alt="Aperçu"
                        className="apercu-image"
                    />
                    </div>
                )}
                </div>
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={handleAddEvent}>
                    Ajouter
                    </Button>
                </Box>
            </Container>

            <div className="seperation"></div>

            <h4 className="h4-annonce"><img src={calendarImg}/>Événements</h4>

            <Paper elevation={3} className="calendar-head">
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="h6">
                {monthName}
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                </Button>
            </Grid>
            </Grid>
            </Paper>

            <div className="events">
                {(events == null) ? (
                    <div className="event">
                        <h1><Skeleton /></h1>
                        <Skeleton height={200} />
                        <p><Skeleton count={5} /></p>
                    </div>
                ) : ((events.length === 0) ? (
                    <p>Il n'y a pas encore d'événement prévu...</p>
                ) : (
                    events.map((elt, key) => ((elt.id==editEventId)?<>
                        <div key={key} className="event update">
                            <div className="head">
                                <TextField
                                    label="Titre"
                                    variant="outlined"
                                    fullWidth
                                    value={titleMod}
                                    onChange={(e) => setTitleMod(e.target.value)}
                                    margin="normal"
                                />
                                <button className="del-button" onClick={() => handleCancelEvent()}>Annuler</button>
                            </div>
                            <div className="event-image">
                                <img src={(fichierSelectionneMod)?URL.createObjectURL(fichierSelectionneMod):elt.picture} alt={elt.title} />
                            </div>
                            <div className="event-details"> 
                                <TextField
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={descriptionMod}
                                    onChange={(e) => setDescriptionMod(e.target.value)}
                                    margin="normal"
                                />
                                <div className="date-details">
                                    <div className="text-field">
                                        <p>Date de l'événement :</p>
                                        <TextField
                                            variant="outlined"
                                            type="date"
                                            value={dateMod}
                                            onChange={(e) => setDateMod(e.target.value)}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="text-field">
                                        <p>Heure de l'événement :</p>
                                        <TextField
                                            variant="outlined"
                                            type="time"
                                            value={heureMod}
                                            onChange={(e) => setHeureMod(e.target.value)}
                                            margin="normal"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="event-update">
                                <div className="composant-telechargement-fichier">
                                <label className={`label-fichier ${fichierSelectionneMod ? 'selected' : ''}`}>
                                    <input
                                    type="file"
                                    className="input-fichier"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleChangementFichierMod}
                                    />
                                    <span className="texte-label-fichier">
                                    {fichierSelectionneMod ? 'Nouvelle image selectionné' : 'Modifier l\'image'}
                                    </span>
                                </label>
                                </div>
                                <button className="edit-button" onClick={handleSendEditEvent}>Sauvegarder</button>
                            </div>
                        </div>
                    </>:<>
                        <div key={key} className="event">
                            <div className="head">
                                <h1>{elt.title}</h1>
                                {/* <div className="entity-info">
                                    <img src={elt.entity.picture} alt={elt.entity.name} className="entity-logo" />
                                    <p className="entity-name">{elt.entity.name}</p>
                                </div> */}

                                <div className="event-update">
                                    <button className="edit-button" onClick={() => handleEditEvent(elt)}>Modifier</button>
                                    <button className="del-button" onClick={() => handleDelEvent(elt.id)}>X</button>
                                </div>
                            </div>
                            <div className="event-image">
                                <img src={elt.picture} alt={elt.title} />
                            </div>
                            <div className="event-details"> 
                                <p className="event-description">{elt.description}</p>
                                <div className="date-details">
                                    <p className="event-date">Date: {new Date(elt.date).toLocaleString('fr-FR', { dateStyle: 'long', timeStyle: 'short' })}</p>
                                    <p>De <span>{elt.poster.name+" "+elt.poster.last_name}</span></p>
                                </div>
                            </div>
                        </div>
                    </>))
                ))}
            </div>

        </>:(selectOption=="Membres")?<>
            <div className="member-page">
                {(id_club)&&<>
                    <label>Membres:</label>
                    {(club).members&&(club).members.length==0?<>
                        <p>Pour l'instant, il n'y aucun membre.</p>
                    </>:<>
                        <ul>
                            {(club).members&&(club).members.map((elt,key) => 
                                <li key={key}>{elt.name+" "+elt.last_name}<button className="del-button" onClick={e => handleDelMember(elt.id)}>X</button></li>
                            )}
                        </ul>
                    </>}
                </>}

                <p>En ajouter ?</p>
                <div className="search">
                    <SearchIcon className="icon" />
                    <TextField
                        placeholder="Rechercher dans les élèves"
                        variant="outlined"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        fullWidth
                        size="small"
                        InputProps={{
                        style: {
                            padding: '8px 0',
                        },
                        }}
                    />
                </div>
                {members&&<ul>
                    {members.filter((elt, key) => ((elt.last_name+" "+elt.name).toLowerCase().includes(search.toLowerCase()) || (elt.name+" "+elt.last_name).toLowerCase().includes(search.toLowerCase()))).map((elt,key) => 
                        <li key={key}>{elt.name+" "+elt.last_name}<button className="add-button" onClick={e => handleAddMember(elt.id)} >+</button></li>
                    )}
                </ul>}
            </div>
            <div className="bottom"></div>
        </>:<>
            <div className="modif-page">
                <p>Modification de la page</p>
                <form>
                <TextField
                    label="Titre"
                    fullWidth
                    variant="outlined"
                    value={titlePage}
                    onChange={(e) => setTitlePage(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={descriptionPage}
                    onChange={(e) => setDescriptionPage(e.target.value)}
                    margin="normal"
                />
                <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChangePage}
                    style={{ display: 'none' }}
                    id="image-upload"
                />
                <label htmlFor="image-upload">
                    <Button variant="contained" component="span">
                    {!imagePage?"Choisir une image":"Modifier l'image"}
                    </Button>
                </label>
                {imagePage && <p>Image sélectionnée: {imagePage.name}</p>}
                <Box id="save-button" mt={2}>
                    <Button className="send-button" variant="contained" color="primary" onClick={handleSubmit}>
                    Enregistrer les modifications
                    </Button>
                </Box>
                </form>
            </div>
        </>}
        
    </>);

    return (<div className="page" id="servicePSNPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <NavBar titre="PSN" psn={true} />
        <div className="information" id="information">
            {!user&&localStorage.getItem("token") ? (
                <>
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                </>
            ) : (
                <>
                    {user&&user.is_je ? (
                        <>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group" className="buttons" id="top-button">
                                <Button onClick={() => handleClick(true)}>Gérer PSN</Button>
                                <Button onClick={() => handleClick(false)}>Visiteur</Button>
                            </ButtonGroup>
                            {isManagePSN ? (
                                adminContent
                            ) : (
                                visitorContent
                            )}
                        </>
                    ) : (
                        visitorContent
                    )}
                </>
            )}
            <div className="bottom"></div>
        </div>
        <Menu psn={true} />
    </div>
    );
};