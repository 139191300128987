import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import React, {useState, useEffect} from 'react';

import psnImg from '../images/psnLogo.png';
import soccerImg from '../images/Clubsbureaux.png';
import handshakeImg from '../images/Partenaires.png';
import shopImg from '../images/Goodies.png';
import logoImg from '../images/icon.png';
import officeImg from '../images/groups.png';
import parameterImg from '../images/Profil.png'
import calendrierImg from '../images/Calendrier.png'

import {getUser, handleResponse} from '../utils/utils';

import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';


import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const HomePage = (props) => {
    const {setUser} = props;

    const [user, setUserNew] = useState(null);
    const [favoris, setFavoris] = useState(0);

    const loadFavoris = () => {
        fetch("/api/offices/favoris", {
            method: "GET"
        })
        .then(res => handleResponse(res, false, (data)=> {
            setFavoris(data.data);
        })).catch(e => {
            setFavoris(null);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    useEffect(() => {
        if(localStorage.getItem("token")){
            getUser((data) => {
                if(data){
                    setUserNew(data);
                    setUser(data);
                }
            });
        }
        loadFavoris();
    }, []);

    return (<div className="page" id="homePage">
        <NavBar titre="Services" removeBack={true}/>
        <div className="information">
            {favoris!==0&&favoris!==null?<div className="element">
                <Link to={"/services/club?id="+favoris.id}>
                    <img src={favoris.picture} alt=""/>
                    <p>{favoris.name}</p>
                </Link>
            </div>:(favoris!==null)&&<div className="element">
                <h3><Skeleton /></h3>
                <h3><Skeleton height={80}/></h3>
            </div>}
            <div className="element">
                <Link to="/services/club">
                    <img src={soccerImg} alt=""/>
                    <p>Bureaux/Clubs</p>
                </Link>
            </div>
            <div className="element">
                <Link to="/services/partners">
                    <img src={handshakeImg} alt=""/>
                    <p>Partenaires</p>
                </Link>
            </div>
            <div className="element">
                <Link to="/services/goodies">
                    <img src={shopImg} alt=""/>
                    <p>Goodies</p>
                </Link>
            </div>
            <div className="element">
                <Link to="/services/psn">
                    <img src={psnImg} alt=""/>
                    <p>Polytech Services Nancy</p>
                </Link>
            </div>
            <div className="element">
                <Link to="/events">
                    <img src={calendrierImg} alt=""/>
                    <p>Calendrier</p>
                </Link>
            </div>
            {user&&(user.is_admin_office||user.is_admin)&&<div className="element">
                <Link to="/services/admin_office">
                    <img src={officeImg} alt=""/>
                    <p>Gestion Bureaux/Clubs</p>
                </Link>
            </div>}
            {user&&user.is_admin&&<div className="element">
                <Link to="/services/admin">
                    <img src={parameterImg} alt=""/>
                    <p>Admin</p>
                </Link>
            </div>}
            <div className="bottom"></div>
        </div>
        <Menu />
    </div>)
}


export default HomePage;