import { NotificationManager } from 'react-notifications';
import { Buffer } from 'buffer';

export const getUser = (cb) => {
    fetch("/api/me", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "token": localStorage.getItem("token")
        }
    }).then(async res => {
        //console.log(res);
        if(res.status === 200){
            const data = await res.json();
            if(data.data){
                cb(data.data);
            }
        }else{
            cb(null);
        }
    })
}

export const logout = (setUser) => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("startsession");

    setUser(null);
};

export const isSessionFinished = async (cb) => {
    const expiration = await localStorage.getItem("expiration");
    if(!expiration || (new Date()).getTime() - parseInt(expiration) >= 0){
        return cb(true);
    }

    return cb(false);
};

export const isSessionNeedToBeReload = async (cb) => {
    const start = await localStorage.getItem("startsession");
    //changement de token toutes les heures
    if(!start || (new Date()).getTime() - parseInt(start) <= 1 * 60 * 60 * 1000){
        return cb(false);
    }else{

        //reload session
        fetch("/api/login/refresh", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "token": localStorage.getItem("token")
            },
        }).then(async res => {
            if(res.status !== 200){
                throw "Erreur lors de la réception de la réponse";
            }else{
                const data = await res.json();

                if(data.token){
                    const token = String(data.token);

                    const expiration = parseInt(JSON.parse(Buffer.from(token.split(".")[1], "base64").toString("utf-8"))["exp"])*1000;
                    const startsession = (new Date()).getTime();
                    localStorage.setItem("token", token);
                    localStorage.setItem("expiration", expiration);
                    localStorage.setItem("startsession", startsession);
                }
            }
        }).catch((e) => {
            NotificationManager.warning("Le rechargement du token n'a pas fonctionné...");
        });

        return cb(true);
    }
};


export const handleResponse = async (rep, wantMessage, cb) => {
    let data = await rep.json();
    if(rep.status === 200){

        if(data.message&&wantMessage){
            NotificationManager.success(data.message);
        }
        
        cb(data);
    }else{
        if(data.data&&data.data.error){
            NotificationManager.warning(data.data.error);
        }else if(data.message){
            NotificationManager.warning(data.message);
        }
        cb(null);
    }
}

