import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import React, {useState, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import {Navigate, useNavigate} from 'react-router-dom';

import logo from '../images/icon.png';
import settingImg from '../images/setting.png';
import logoutImg from '../images/logout.png';
import closeImg from '../images/close.png';
import image from '../images/teest.png';

import {getUser, handleResponse, isSessionFinished, logout} from '../utils/utils';

import DialogLoading from '../utils/DialogLoading';
import { Container, TextField, Button, Grid, Typography } from '@mui/material';

import {Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';


import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Confetti from 'react-confetti'

const UserPage = (props) => {
    const {setUser, user} = props; 

    const [setting, setSetting] = useState(false);

    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");

    const [badges, setBadges] = useState(null);
    const [loading, setLoading] = useState(false);

    const [easy, setEasy] = useState(false);
    const [egg, setEgg] = useState(localStorage.getItem("egg")==1);
    let timer = -1;

    const navigate = useNavigate();

    const width = window.innnerWidth;
    const height = window.innerHeight;

    const handleSendPassword = () => {
        setLoading(true);
        fetch("/api/me/changePassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "token": localStorage.getItem("token")
            },
            body: JSON.stringify({
                password,
                confirm_password:confirmationPassword,
                old_password: oldPassword
            })
        }).then((res) => handleResponse(res, true, (data) => {
            setLoading(false);
            getUser(setUser);
            if(data){
                setSetting(false);
                setPassword("");
                setConfirmationPassword("");
                setOldPassword("");
                setNom("");
                setPrenom("");
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const handleSendIdentity = () => {
        setLoading(true);
        fetch("/api/me/changeUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "token": localStorage.getItem("token")
            },
            body: JSON.stringify({
                name:prenom,
                last_name:nom
            })
        }).then((res) => handleResponse(res, true, (data) => {
            setLoading(false);
            //on init le user
            getUser(setUser);
            if(data){
                setSetting(false);
                setPassword("");
                setConfirmationPassword("");
                setOldPassword("");
                setNom("");
                setPrenom("");
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const handleSup = () => {
        setSup(false);
        if(localStorage.getItem("token")){
            setLoading(true);
            fetch("/api/me/delete", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                logout(setUser);
                //window.location.reload();
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const [sup, setSup] = useState(false);

    const launchSup = () => {
        setSup(true);
    };

    const loadBadges = () => {
        if(localStorage.getItem("token")){
            fetch("/api/me/badges", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data => {
                if(data){
                    if(data.data){
                        setBadges(data.data);
                    }
                }
            }))).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const handleClick = () => {
        if(timer == -1){
            timer = 1;
            setTimeout(() => {
                if(timer >= 5){
                    setEasy(true);
                }
                timer = -1;
            }, 600);
        }else{
            timer = timer+1;
        }
    }

    useEffect(() => {
        loadBadges();
    }, []);

    return user?(<div className="page" id="userPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <NavBar titre="Mon profil"/>
        <div className="information">
            {(!setting)?<>
                <button className="logo-button" onClick={handleClick}><img src={logo} alt="CDE Polytech Nancy"/></button>

                <div className="text">
                    <p>Email: {user.email}</p>
                    <p>Prenom: {user.name}</p>
                    <p>Nom: {user.last_name}</p>
                    <p>Badges:</p>
                    <div className="badges">
                        <ul>
                            {(badges)?<>
                                {badges.is_admin&&<li className="admin">Admin</li>}
                                {badges.is_je&&<li className="je">JE</li>}
                                {badges.is_office&&<li className="gestion-bureau">Bureau</li>}
                                {badges.is_club&&<li className="gestion-club">Club</li>} 
                                {(egg!=null&&egg==1)&&<li className="egg">Easter Egg</li>}
                                {!(badges.is_club||badges.is_office||badges.is_admin||badges.is_je)&&<li className="banal">Etudiant</li>}
                            </>:<>
                                <li className="fakee"><Skeleton /></li>
                                <li className="fakee"><Skeleton /></li>
                                <li className="fakee"><Skeleton /></li>
                            </>}
                        </ul>
                    </div>
                </div>
                <div className="text-button">
                    <button onClick={(e) => {setSetting((value)=>!value);}}><img src={settingImg}/>Paramètres</button>
                </div>
                <button className="logout" onClick={() => {logout(setUser); NotificationManager.info("Vous êtes bien déconnecté !");}}><img src={logoutImg}/>Déconnexion</button>
            </>:<>
                <Container maxWidth="sm" className="middle">
                <Typography variant="h4">Votre mot de passe</Typography>
                <div className="inputs">
                    <TextField
                    type="password"
                    label="Ancien mot de passe"
                    variant="outlined"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <TextField
                    type="password"
                    label="Mot de passe"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                    type="password"
                    label="Confirmation du mot de passe"
                    variant="outlined"
                    value={confirmationPassword}
                    onChange={(e) => setConfirmationPassword(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleSendPassword}>
                    Okay
                    </Button>
                </div>

                <Typography variant="h4">Votre Identité</Typography>
                <div className="inputs">
                    <TextField
                    label="Nom"
                    variant="outlined"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    />
                    <TextField
                    label="Prenom"
                    variant="outlined"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleSendIdentity}>
                    Okay
                    </Button>
                </div>
                </Container>
                <div className="text-button">
                    <button onClick={(e) => {setSetting((value)=>!value);}}><img src={settingImg}/>Informations</button>
                    {/* <p>Vous voulez supprimer vos données ?</p> */}
                    <button className="sup-button" onClick={launchSup}><img src={closeImg}/>Supprimer votre compte</button>
                </div>
            </>}
            <div className="bottom"></div>
        </div>
        <Menu />

        {easy&&<div className="screen">
            <button onClick={() => {
                setEasy(false);
            }} className="close-img"><img src={closeImg}/></button>
            <Confetti
            className="confetti"
            width={width}
            height={height}
            />
            <div className="center">
                <h1>{atob("RUFTVEVSIEVHRw==")}</h1>
                <img alt="Image" src={image} /> 
                {/* <FormControlLabel control={<Switch checked={egg} onChange={(e) => {
                    // console.log(e.target.checked);
                    setEgg(e.target.checked?1:0);
                    localStorage.setItem("egg", e.target.checked?1:0);
                }} />} label="Activer le easter egg ?" /> */}
            </div>
        </div>}

        <Dialog
            open={sup}
            onClose={() => setSup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Suppression de votre de compte"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Etes vous sûr de supprimer votre compte?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setSup(false)} autoFocus>Nope</Button>
            <Button onClick={() => handleSup()} >
                OUI
            </Button>
            </DialogActions>
        </Dialog>

    </div>):<>
        <Navigate to="/login" />
    </>;
}


export default UserPage;