import React, {useState, useEffect} from 'react';

import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import backImg from '../images/left-arrow.png';

import { Button, TextField, TextareaAutosize, NativeSelect, Switch } from '@mui/material';

import { handleResponse } from '../utils/utils';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {scroller as scroll, scrollSpy, Element, Link as LinkScroll} from 'react-scroll';

import DialogLoading from '../utils/DialogLoading';

import plusImg from '../images/plus.png';
import minusImg from '../images/close.png';
import checkImg from '../images/check-mark.png';
import bellImg from '../images/bell.png';
import bellOKImg from '../images/bell_ok.png';

import { NotificationManager } from 'react-notifications';

import annonceImg from '../images/megaphone.png';
import unknownImg from '../images/unknown-mail.png';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import {Typography, Grid, Paper } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default (props) => {
    const {user, setProps} = props;

    const bureauOrphelin = {
        name: "Orphelin",
        id: "null",
        picture: "null",
        members: [],
        description: "Ce n\'est pas un bureau mais plutôt un non-bureau. \n \
        C\'est ici que les clubs sans bureaux se retrouvent tous..."
    };

    const [useParam, ] = useSearchParams();
    const navigate = useNavigate();

    const [dialogNotif, setDialogNotif] = useState(null);
    const [dialogSupp, setDialogSupp] = useState(null);

    const [data, setData] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [picture, setPicture] = useState(null);
    const [preview, setPreview] = useState(null);

    const [description, setDescription] = useState("");
    const [titre, setTitre] = useState("");
    const [option, setOption] = useState("none");

    const [modification, setModification] = useState(null);

    const [loading, setLoading] = useState(false);


    const id_bureau = useParam.get("id");
    const [idBureau, setIdBureau] = useState(null);
    const [bureau, setBureau] = useState(null);


    const [dataClub, setDataClub] = useState(null);


    const id_club = useParam.get("id_club");
    const [idClub, setIdClub] = useState(null);
    const [club, setClub] = useState(null);

    const sub_id = useParam.get("sub_id");

    const [favorisOption, setFavorisOption] = useState(false);


    const [follow, setFollow] = useState(null);
    const [bell, setBell] = useState(true);

    const [annonces, setAnnonces] = useState(null);


    const handleClose = () => {

    }

    const [currentMonth, setCurrentMonth] = useState((!useParam.get("date"))?new Date():new Date(useParam.get("date")));

    const previousMonth = () => {
        const prevMonth = new Date(currentMonth);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        if(club){
            loadAnnonces(club.id, "club", prevMonth);
        }else{
            loadAnnonces(bureau.id, "office", prevMonth);
        }
        setCurrentMonth(prevMonth);
    };

    const nextMonth = () => {
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        if(club){
            loadAnnonces(club.id, "club", nextMonth);
        }else{
            loadAnnonces(bureau.id, "office", nextMonth);
        }
        setCurrentMonth(nextMonth);
    };

    let monthName = new Intl.DateTimeFormat('fr', { month: 'long' }).format(currentMonth);
    monthName = monthName[0].toUpperCase()+monthName.substring(1);
    
    const handleSendNotif = (id) => {
        if(localStorage.getItem("token")&&user&&user.is_admin){
            setLoading(true);
            fetch("/api/subscriptions/notif/force_one", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id,
                    type: "post"
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){

                }else{

                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
        handleCloseNotif();
    }

    const handleCloseNotif = () => {
        setDialogNotif(null);
        setDialogSupp(null);
    }

    const checkFollow = (id, type) => {
        setFollow(null);
        checkBell(id, type);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/one?id="+encodeURIComponent(id)+"&type="+type, {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setFollow(data.data.follow);
                    }
                }else{
                    setFollow(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const checkBell = (id, type) => {
        setBell(null);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/notif/one?id="+encodeURIComponent(id)+"&type="+type, {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setBell(data.data.bell);
                    }
                }else{
                    setBell(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

 
    const loadDataClub = (id) => {
        if(id!="null"){
            fetch("/api/offices/club?office_id="+encodeURIComponent(id), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setDataClub(data.data);
                    }
                }else{
                    setDataClub(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
        else if(user&&user.is_admin){
            fetch("/api/offices/club/ghost", {
                method:"GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setDataClub(data.data);
                    }
                }else{
                    setDataClub(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const loadAnnonces = (id, type, month) => {
        fetch("/api/offices/posts?id="+encodeURIComponent(id)+"&type="+type+"&month="+encodeURIComponent(month), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setAnnonces(data.data);
                }
            }else{

            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }


    //on charge le club
    if(id_club!=idClub&&id_club){
        setClub(null);
        setBureau(null);
        setIdBureau(null);
        setIdClub(id_club);
        loadAnnonces(id_club, "club", currentMonth);
        fetch("/api/offices/club/one?id="+encodeURIComponent(id_club), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data&&data.data){
                setClub(data.data);
                checkFollow(data.data.id, "club");
            }else{
                setIdClub(null);
                setClub(null);
                navigate("");
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    //on charge le bureau
    if(id_bureau!=idBureau&&id_bureau){
        setBureau(null);
        setIdBureau(id_bureau);
        setIdClub(null);
        setClub(null);
        loadAnnonces(id_bureau, "office", currentMonth);
        if(id_bureau!="null"){
            fetch("/api/offices/one?id="+encodeURIComponent(id_bureau), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data&&data.data){
                    setBureau(data.data);
                    loadDataClub(id_bureau);
                    checkFollow(id_bureau, "office");
                }else{
                    setIdBureau(null);
                    setBureau(null);
                    navigate("");
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }else{
            setBureau(bureauOrphelin);
            loadDataClub("null");
        }
    }


    const loadData = () => {
        fetch("/api/offices", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(data => (handleResponse(data,false,(data) => {
            if(data&&data.data){
                setData(data.data);
            }
        }))).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };


    const handleFollow = (id, type) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    state: !follow,
                    id,
                    type
                })
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    checkFollow(id, type);
                }else{

                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }else{
            NotificationManager.info("Vous ne pouvez pas suivre de bureau ou de club si vous n'avez pas de compte...");
        }
    }

    const handleBell = (id, type) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            fetch("/api/subscriptions/notif/update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    state: !bell,
                    id,
                    type
                })
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    checkBell(id, type);
                }else{

                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }else{
            NotificationManager.info("Vous ne pouvez pas activer les notifications de bureau ou de club si vous n'avez pas de compte...");
        }
    }


    useEffect(() => {
        loadData();
    }, []);
    

    const handleProfil = (e) => {
        setPicture(e.target.files[0]);
        if(preview){
            URL.revokeObjectURL(preview);
        }
        setPreview(URL.createObjectURL(e.target.files[0]));
    };


    const addOffice = () => {
        if(localStorage.getItem("token")){
            setLoading(true);
            const formData = new FormData();
            formData.append("image", picture);
            formData.append("description", description);
            formData.append("name", titre);

            fetch("/api/offices/add", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formData
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadData();
                    
                    handleBack();
                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const launchDelete = (id) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            setDialogSupp(id);
        }
    }

    const handleDelete = (id) => {
        useParam.set("sub_id", undefined);
        setDialogSupp(null);
        if(localStorage.getItem("token")){
            setLoading(true);
            fetch("/api/offices/del", {
                method: "POST",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                loadData();

            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const handleUpdate = (id) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            setLoading(true);
            const formdata = new FormData();
            formdata.append("name", titre);
            formdata.append("id", id);
            formdata.append("description", description);
            formdata.append("favoris", (favorisOption)?1:0);
            if(picture){
                formdata.append("image", picture);
            }
            fetch("/api/offices/update", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formdata
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadData();
                    handleBack();
                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const launchUpdate = (id) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            fetch("/api/offices/one?id="+encodeURIComponent(id), {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data&&data.data){
                    const {name, description, id, picture, favoris} = data.data;
                    setTitre(name);
                    setDescription(description);
                    
                    setPreview(picture);

                    setModification(id);
                    setFavorisOption(favoris);
                    setDialog(true);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    };

    const handleBack = () => {
        setDialog(false);
        setModification(null);
        setTitre("");
        setDescription("");
        setOption("none");
        setPreview(null);
        setPicture(null);
        setBureau(null);
        setIdBureau(null);
        navigate("");
    }

    const handleBackClub = () => {
        setDialog(false);
        setTitre("");
        setDescription("");
        setOption("none");
        setPreview(null);
        setPicture(null);
        setModification(null);
    }

    const handleBackClubClub = () => {
        setDialog(false);
        setTitre("");
        setDescription("");
        setOption("none");
        setPreview(null);
        setPicture(null);
        setModification(null);
        if(club){
            navigate("?id="+encodeURIComponent(club.office_id));
            checkFollow(club.office_id, "office");
        }
    }

    const handleDeleteClub = (id) => {
        useParam.set("sub_id", undefined);
        setDialogSupp(null);
        if(localStorage.getItem("token")){
            setLoading(true);
            fetch("/api/offices/club/del", {
                method: "POST",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                loadDataClub(id_bureau);
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const handleUpdateClub = (id) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            setLoading(true);
            const formdata = new FormData();
            formdata.append("name", titre);
            formdata.append("id", id);
            formdata.append("description", description);
            if(picture){
                formdata.append("image", picture);
            }
            formdata.append("option", option);
            fetch("/api/offices/club/update", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formdata
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadDataClub(id_bureau);
                    handleBackClub();
                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const launchUpdateClub = (id) => {
        useParam.set("sub_id", undefined);
        if(localStorage.getItem("token")){
            fetch("/api/offices/club/one?id="+encodeURIComponent(id), {
                method: "GET",
                headers: {
                    "Content-Type":"application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                
                if(data&&data.data){
                    const {name, description, id, picture} = data.data;
                    setTitre(name);
                    setDescription(description);
                    
                    setPreview(picture);

                    setModification(id);
                    setDialog(true);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const addClub = () => {
        if(localStorage.getItem("token")){
            setLoading(true);
            const formdata = new FormData();
            formdata.append("name", titre);
            formdata.append("description", description);
            formdata.append("office_id", idBureau);
            formdata.append("image", picture);

            fetch("/api/offices/club/add", {
                method: "POST",
                headers: {
                    token: localStorage.getItem("token")
                },
                body: formdata
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadDataClub(id_bureau);
                    handleBackClub();
                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    return (<div className="page" id="serviceClubPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <NavBar titre="Bureaux/Clubs"/>

        <div className="information" id="information">
            <Element name="top" isDynamic={true}></Element>
            {(id_bureau||id_club)?<>
                {(id_bureau)?<>
                    <div className="mainClub">
                    {bureau?<>
                        <button onClick={handleBack} id="top-button">Retour</button>
                        <div className="top">
                            <h3>{bureau.name}</h3>


                            {(bureau.id!="null"&&follow!=null&&follow&&bell!=null)?<>
                                <button title="Pour activer les notifications" onClick={() => handleBell(bureau.id, "office")} className={"bell-button"+((bell)?" activate":"")}><img src={(bell)?bellOKImg:bellImg}/></button>
                            </>:((localStorage.getItem("token")&&follow)&&<>
                                <h3 className="bell-button fakebutton"><Skeleton /></h3>
                            </>)}

                            {(bureau.id!="null"&&follow!=null)?<>
                                <button onClick={() => handleFollow(bureau.id, "office")} className={"follow-button"+((follow)?" activate-follow":"")}><img src={checkImg}/>{(follow)?"Suivi":"Suivre"}</button>
                            </>:(localStorage.getItem("token"))?<>
                                <h3 className="fakebutton"><Skeleton /></h3>
                            </>:<>
                                <button onClick={() => NotificationManager.info("Vous devez vous connecter pour suivre un bureau/club")} className="follow-button"><img src={checkImg}/>Suivre</button>
                            </>}
                        </div>
                        <img src={(bureau.picture!="null")?bureau.picture:unknownImg} />
                        <div className="description-bureau">
                            <label>Description du bureau:</label>
                            <pre>{bureau.description}</pre>
                            {(bureau.id!="null")&&<>
                                <label>Membres:</label>
                                {bureau.members&&bureau.members.length==0?<>
                                    <p>Pour l'instant, il n'y aucun membre.</p>
                                </>:<>
                                    <ul>
                                        {bureau.members&&bureau.members.map((elt,key) => 
                                            <li key={key}>{elt.name+" "+elt.last_name}</li>
                                        )}
                                    </ul>
                                </>}
                            </>}
                        </div>
                        <div className="seperation"></div>
                        {(!dialog)?<>
                            <h4 className="office-h4">Tous les clubs appartenant au {bureau.name}</h4>
                            {user&&user.is_admin&&<>
                                <button onClick={() => {
                                    useParam.set("sub_id", undefined);
                                    setDialog(elt => !elt);
                                }} id="top-button"><img src={plusImg} />Ajouter</button>
                            </>}
                            <div className="clubs">
                                {(dataClub&&dataClub.length!=0)?dataClub.map((elt, key) => {
                                    return (<div key={key} className="element">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                            {user&&user.is_admin&&<button onClick={() => {
                                                launchDelete(elt.id);
                                            }}><img src={minusImg}/></button>}
                                        </div>
                                        <Link to={"?id_club="+elt.id} onClick={() => {
                                            //pour éviter d'avoir le scroll en bas
                                            scroll.scrollTo("top", {
                                                containerId: "information"
                                            });
                                        }}>
                                            <img alt="logo" src={elt.picture} />
                                        </Link>
                                        {/* <p>{elt.description}</p> */}
                                        {user&&user.is_admin&&<button className="button-modifier" onClick={() => launchUpdateClub(elt.id)}>Modifier</button>}
                                    </div>);
                                }):(dataClub&&dataClub.length==0)?<>
                                    <p>Aucun</p>
                                </>:<>
                                    <div className="element">
                                        <h3 className="fake"><Skeleton /></h3>

                                        <p className="fake"><Skeleton height={100}/></p>
                                    </div>
                                    <div className="element">
                                        <h3 className="fake"><Skeleton /></h3>

                                        <p className="fake"><Skeleton height={100}/></p>
                                    </div>
                                </>}
                            </div>
                        </>:<>
                            <button onClick={handleBackClub} id="top-button">Retour au club</button>

                            <div className="configuration">
                                <div className="elt">
                                    <TextField
                                        label="Titre du club"
                                        variant="outlined"
                                        value={titre}
                                        onChange={(e) => setTitre(e.target.value)}
                                    />
                                </div>
                                <div className="elt logo">
                                    {preview && <img src={preview} alt="Preview" />}
                                    <label for="image" className={`file-input-label ${preview ? 'selected' : ''}`}>
                                    {preview ? 'Fichier selectionné' : 'Choisir un fichier'}
                                    </label>
                                    <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleProfil}
                                    />
                                </div>
                                <div className="elt textarea">
                                    <label for="description">Description du club</label>
                                    <TextareaAutosize
                                        name="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        minRows={3}
                                    />
                                </div>
                                {modification && (
                                    <div className="elt textarea">
                                        <label for="transfert">Voulez-vous changer le club de bureau ?</label>
                                        <NativeSelect
                                            name="transfert"
                                            value={option}
                                            onChange={(e) => setOption(e.target.value)}
                                        >
                                            <option value="none">Aucun changement</option>
                                            {bureau&&data&&data.filter(elt => elt.id!=bureau.id).map(elt => {
                                                return (<option value={elt.id}>{elt.name}</option>)
                                            })}
                                            {bureau&&bureau.id!="null"&&<option value="orphelin">Orphelin</option>}
                                        </NativeSelect>
                                    </div>
                                )}
                                {modification ? (
                                    <Button variant="contained" onClick={() => handleUpdateClub(modification)}>
                                        Modifier
                                    </Button>
                                ) : (
                                    <Button variant="contained" onClick={addClub} startIcon={<img src={plusImg} />}>
                                        Ajouter
                                    </Button>
                                )}
                            </div>
                        </>}
                        {(bureau.id!="null")&&<>

                            <div className="seperation"></div>
                            <h4><img src={annonceImg}/>Annonces</h4>


                            <Paper elevation={3} className="calendar-head">
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                {monthName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                                </Button>
                            </Grid>
                            </Grid>
                            </Paper>

                            {user&&user.is_admin&&<Accordion id="notif-info">
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Gérer les notifications</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Vous pouvez forcer l'envoie de notification pour une annonce en cliquant sur le bouton prévu à cet effet.<br/>
                                    Cela vous demandera de confirmer puis enverra la notification sur tous les téléphones disponibles.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>}

                            {(annonces!=null)?<>
                                {(annonces.length==0)?<>
                                    <p>Il n'y a pas d'annonce.</p>
                                </>:<div className="annonces">
                                    {annonces.map((elt, key) => {
                                        let { title, description, type, poster, createdAt } = elt;
                                        createdAt = new Date(createdAt);
                                        const options = {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        };
                                        scroll.scrollTo(sub_id, {
                                            offset: -30,
                                            containerId: "information",
                                        });

                                        const dateLocaleFr = createdAt.toLocaleDateString('fr-FR', options);
                                        return (
                                        <div key={key} className={"annonce"+((sub_id&&elt.id==sub_id)?" selected":"")}>
                                            
                                                <Element name={elt.id} isDynamic={true}></Element>
                                                <h3>{title}</h3>
                                                <p className="description">{description}</p>
                                                <div className="details">
                                                    <p className="poster">{poster.last_name + ' ' + poster.name}</p>
                                                    <p className="date">{dateLocaleFr}</p>
                                                </div>
                                                {user&&user.is_admin&&<button className="notif-button" onClick={() => setDialogNotif(elt.id)}>Envoie de notification</button>}
                                        </div>
                                        );
                                    })}
                                </div>}
                            </>:<>
                                <h3><Skeleton /></h3>
                                <p><Skeleton count={4}/></p>
                            </>}
                        </>}

                        <div className="bottom"></div>
                    </>:<>
                        <button onClick={handleBack} id="top-button">Retour</button>
                        <h3 className="fake"><Skeleton /></h3>
                        <h3 className="fake"><Skeleton height={150} /></h3>
                        <div className="description-bureau">
                            <label>Description du bureau:</label>
                            <p><Skeleton count={3}/></p>
                            <label>Membres:</label>
                            <p><Skeleton count={1}/></p>
                        </div>
                        <div className="seperation"></div>
                    </>}
                    </div>
                </>:<>
                    <div className="mainClub">
                    {club?<>
                        <button onClick={handleBackClubClub} id="top-button">Retour</button>
                        <div className="top">
                            <h3>{club.name}</h3>

                            {(club&&follow!=null&&follow&&bell!=null)?<>
                                <button title="Pour activer les notifications" onClick={() => handleBell(club.id, "club")} className={"bell-button"+((bell)?" activate":"")}><img src={(bell)?bellOKImg:bellImg}/></button>
                            </>:((localStorage.getItem("token")&&follow)&&<>
                                <h3 className="bell-button fakebutton"><Skeleton /></h3>
                            </>)}

                            
                            {(club&&follow!=null)?<>
                                <button onClick={() => handleFollow(club.id, "club")} className={"follow-button"+((follow)?" activate-follow":"")}><img src={checkImg}/>{(follow)?"Suivi":"Suivre"}</button>
                            </>:(localStorage.getItem("token"))?<>
                                <h3 className="fakebutton"><Skeleton /></h3>
                            </>:<>
                                <button onClick={() => NotificationManager.info("Vous devez vous connecter pour suivre un bureau/club")} className="follow-button"><img src={checkImg}/>Suivre</button>
                            </>}
                        </div>
                        <img src={club.picture} />
                        <div className="description-bureau">
                            <label>Description du club:</label>
                            <pre>{club.description}</pre>
                            <label>Membres:</label>
                            {club.members&&club.members.length==0?<>
                                <p>Pour l'instant, il n'y aucun membre.</p>
                            </>:<>
                                <ul>
                                    {club.members&&club.members.map((elt,key) => 
                                        <li key={key}>{elt.name+" "+elt.last_name}</li>
                                    )}
                                </ul>
                            </>}
                        </div>

                        <div className="seperation"></div>

                        <h4 id="annonces"><img src={annonceImg}/>Annonces</h4>

                        <Paper elevation={3} className="calendar-head">
                        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={previousMonth}>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                            {monthName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" endIcon={<ChevronRightIcon />} onClick={nextMonth}>
                            </Button>
                        </Grid>
                        </Grid>
                        </Paper>

                        {user&&user.is_admin&&<Accordion id="notif-info">
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Gérer les notifications</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                Vous pouvez forcer l'envoie de notification pour une annonce en cliquant sur le bouton prévu à cet effet.<br/>
                                Cela vous demandera de confirmer puis enverra la notification sur tous les téléphones disponibles.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>}

                        {(annonces!=null)?<>
                            {(annonces.length==0)?<>
                                <p>Il n'y a pas d'annonce.</p>
                            </>:<div className="annonces">
                                {annonces.map((elt, key) => {
                                    let { title, description, type, poster, createdAt } = elt;
                                    createdAt = new Date(createdAt);
                                    const options = {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    };
                                    scroll.scrollTo(sub_id, {
                                        offset: -30,
                                        containerId: "information",
                                    });

                                    const dateLocaleFr = createdAt.toLocaleDateString('fr-FR', options);
                                    return (
                                    <div key={key} className={"annonce"+((sub_id&&elt.id==sub_id)?" selected":"")}>
                                            <Element name={elt.id} isDynamic={true}></Element>
                                            <h3>{title}</h3>
                                            <p className="description">{description}</p>
                                            <div className="details">
                                                <p className="poster">{poster.last_name + ' ' + poster.name}</p>
                                                <p className="date">{dateLocaleFr}</p>
                                            </div>
                                            {user&&user.is_admin&&<button className="notif-button" onClick={() => setDialogNotif(elt.id)}>Envoie de notification</button>}
                                    </div>
                                    );
                                })}
                            </div>}
                        </>:<>
                            <h3><Skeleton /></h3>
                            <p><Skeleton count={4}/></p>
                        </>}

                        <div className="bottom"></div>
                    </>:<>
                        <button onClick={handleBack} id="top-button">Retour</button>
                        <h3 className="fake"><Skeleton /></h3>
                        <h3 className="fake"><Skeleton height={150} /></h3>
                        <div className="description-bureau">
                            <label>Description du club:</label>
                            <p><Skeleton count={3}/></p>
                            <label>Membres:</label>
                            <p><Skeleton count={1}/></p>
                        </div>
                        <div className="seperation"></div>
                    </>}
                    </div>
                </>}
            </>:<>
                {(!dialog)?<>
                    {user&&user.is_admin&&<>
                        <button onClick={() => setDialog(elt => !elt)} id="top-button"><img src={plusImg} />Ajouter</button>
                    </>}
                    <h4>Explorez tous les bureaux et clubs disponibles dans notre école ici !</h4>
                    <div className="offices">
                        {
                            (data&&data.length!=0)?data.map((elt, key) => {
                                return (<div key={key} className="element">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                            {user&&user.is_admin&&<button onClick={() => launchDelete(elt.id)}><img src={minusImg}/></button>}
                                        </div>
                                        <Link to={"?id="+elt.id}>
                                            <img alt="logo" src={elt.picture} />
                                        </Link>
                                        {/* <p>{elt.description}</p> */}
                                        {user&&user.is_admin&&<button className="button-modifier" onClick={() => launchUpdate(elt.id)}>Modifier</button>}
                                </div>);
                            }):(data&&data.length==0)?<>
                                <p>Aucun bureau disponible</p>
                            </>:<>
                                <div className="element">
                                    <div className="top">
                                        <h3><Skeleton /></h3>
                                    </div>
                                    
                                    <div className="top">
                                        <h3><Skeleton height={70} /></h3>
                                    </div>
                                </div>
                            </>
                        }

                        {user&&user.is_admin&&<>
                            <div className="element">
                                <div className="top">
                                    <h3>{bureauOrphelin.name}</h3>
                                </div>
                                <Link to={"?id="+bureauOrphelin.id}>
                                    <img alt="logo" src={unknownImg} />
                                </Link>
                            </div>
                        </>}
                    </div>
                </>:<>
                    <button onClick={handleBack} id="top-button">Retour</button>

                    <div className="configuration">
                        <div className="elt">
                            <TextField
                                label="Titre du bureau"
                                variant="outlined"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                            />
                        </div>
                        <div className="elt logo">
                            {preview && <img src={preview} alt="Preview" />}
                            <label for="image" className={`file-input-label ${preview ? 'selected' : ''}`}>
                            {preview ? 'Fichier selectionné' : 'Choisir un fichier'}
                            </label>
                            <input
                            type="file"
                            id="image"
                            name="image"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleProfil}
                            />
                        </div>
                        <div className="elt textarea">
                            <label for="description">Description du bureau</label>
                            <TextareaAutosize
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                minRows={3}
                            />
                        </div>
                        {modification ? (<>
                            <div className="elt switch">
                                <Switch checked={favorisOption} onChange={(e) => setFavorisOption(e.target.checked)} />
                                <p>Voulez-vous le mettre en favoris sur le menu ?</p>
                            </div>
                            <Button variant="contained" onClick={() => handleUpdate(modification)}>
                                Modifier
                            </Button>
                        </>) : (
                            <Button variant="contained" onClick={addOffice} startIcon={<img src={plusImg} />}>
                                Ajouter
                            </Button>
                        )}
                    </div>
                </>}

                <div className="bottom">
                    <Element name="bottom" isDynamic={true}></Element>
                </div>
            </>}
            <Dialog
                open={user&&user.is_admin?dialogSupp:false}
                onClose={handleCloseNotif}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Suppression {(!id_bureau)?"bureau":"club"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Etes vous sûr de supprimer ce {(!id_bureau)?"bureau":"club"} ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNotif} autoFocus>Nope</Button>
                <Button onClick={() => ((!id_bureau)?handleDelete(dialogSupp):handleDeleteClub(dialogSupp))} >
                    LET'S GO
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={user&&user.is_admin?dialogNotif:false}
                onClose={handleCloseNotif}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Envoie de notification"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Etes vous sûr d'envoyer une notification à tous les téléphones pour cette annonce ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNotif} autoFocus>Nope</Button>
                <Button onClick={() => handleSendNotif(dialogNotif)} >
                    LET'S GO
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        <Menu />
    </div>);
};