import React, {useState, useEffect} from 'react';

import NavBar from '../components/Nav';
import Menu from '../components/Menu';

import backImg from '../images/close.png';

import { NotificationManager } from 'react-notifications';

import { handleResponse } from '../utils/utils';

import plusImg from '../images/plus.png';
import closeImg from '../images/close.png';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import DialogLoading from '../utils/DialogLoading';

export default (props) => {
    const {user, setUser} = props;

    const [members, setMembers] = useState(null);
    const [member, setMember] = useState(null);
    const [search, setSearch] = useState("");

    const [modification, setModification] = useState(null);

    const [isJe, setIsJe] = useState(null);
    const [isAdmin, setIsAdmin] = useState(null);

    const [offices, setOffices] = useState(null);
    const [clubs, setClubs] = useState(null);

    const [modeAjout, setModeAjout] = useState(null);
    const [dataOffice, setDataOffice] = useState(null);
    const [dataClub, setDataClub] = useState(null);

    const [loading, setLoading] = useState(false);

    const loadData = () => {
        fetch("/api/members/all", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setMembers(data.data);
                    // let d = [];
                    // for(let i=0; i <30; i++){
                    //     d.push({
                    //         last_name: "Test"+String(i),
                    //         name: "Test"+String(i),
                    //         email: String(i)+"test@test.com",
                    //         id: 1
                    //     });
                    // }
                    // setMembers(d);
                }
            }else{
                setMembers(null);
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const sendNotification = () => {
        if(user&&user.is_admin&&modification){
            setLoading(true);
            fetch("/api/subscriptions/notif/one", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                    id: modification
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadEntities = (id) => {
        fetch("/api/members/belongs_to?id="+encodeURIComponent(modification||id), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            }
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    const {offices, clubs} = data.data;
                    if(offices){
                        setOffices(offices);
                    }
                    if(clubs){
                        setClubs(clubs);
                    }
                }
            }else{
                setOffices(null);
                setClubs(null);
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const loadMember = (id) => {
        if(modification||id){

            fetch("/api/members/one?id="+encodeURIComponent(modification||id), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                }
            }).then(res => handleResponse(res, false, (data) => {
                if(data){
                    if(data.data){
                        setMember(data.data);
                        setIsJe(data.data.is_je);
                        setIsAdmin(data.data.is_admin);
                    }
                }else{
                    setMember(null);
                }
            })).catch(e => {
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })

            loadEntities(id);
        }
    }

    const launchModification = (id) => {
        setModification(id);
        loadMember(id);
    }

    const handleBack = () => {
        setModification(null);
        setMember(null);

        loadData();
    }

    const handleBackBack = () => {
        setModeAjout(null);
        setDataOffice(null);
        setDataClub(null);
        loadEntities();
    }

    const handleJE = () => {
        setLoading(true);
        fetch("/api/members/gain_privileges", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                isje: !isJe,
                id: modification
            })
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                loadMember();
            }else{
                setMember(null);
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const handleAdmin = () => {
        setLoading(true);
        fetch("/api/members/gain_privileges", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
            body: JSON.stringify({
                isadmin: !isAdmin,
                id: modification
            })
        }).then(res => handleResponse(res, true, (data) => {
            setLoading(false);
            if(data){
                loadMember();
            }else{
            }
        })).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const launchOffice = () => {
        setModeAjout("office");
        fetch("/api/offices", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setDataOffice(data.data);
                }
            }else{
                setDataOffice(null);
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const launchClub = () => {
        setModeAjout("club"); 
        fetch("/api/offices/club/all", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token")
            },
        }).then(res => handleResponse(res, false, (data) => {
            if(data){
                if(data.data){
                    setDataClub(data.data);
                }
            }else{
                setDataClub(null);
            }
        })).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    const addRelation = (id, type) => {
        if(type=="office"||type=="club"){
            setLoading(true);
            fetch("/api/members/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id,
                    user_id:modification,
                    type
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadEntities();
                    handleBackBack();
                }else{

                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    const handleDeleteRelation = (id, type) => {
        if(type=="office"||type=="club"){
            setLoading(true);
            fetch("/api/members/del", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id,
                    type,
                    user_id: modification
                })
            }).then(res => handleResponse(res, true, (data) => {
                setLoading(false);
                if(data){
                    loadEntities();
                }else{

                }
            })).catch(e => {
                setLoading(false);
                NotificationManager.warning("Il y a eu une erreur côté serveur...");
            })
        }
    }

    return (<div className="page" id="serviceAdminPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <NavBar titre="Admin"/>
        <div className="information">
            {(!modification)?<>
                <h4>Gestion des rôles des utilisateurs</h4>
                <div className="search">
                    <SearchIcon className="icon" />
                    <TextField
                        placeholder="Rechercher dans les élèves"
                        variant="outlined"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        fullWidth
                        size="small"
                        InputProps={{
                        style: {
                            padding: '8px 0',
                        },
                        }}
                    />
                </div>
                {members&&<p className="nbrUser">Il y a {members.length} étudiants</p>}
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Email</th>
                                <th>Admin</th>
                                <th>JE</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members&&members.filter((elt,key) => ((elt.last_name+" "+elt.name).toLowerCase().includes(search.toLowerCase()) || (elt.name+" "+elt.last_name).toLowerCase().includes(search.toLowerCase()))).map((elt, key) => (<tr key={key}>
                                <td>{elt.last_name}</td>
                                <td>{elt.name}</td>
                                <td>{elt.email}</td>
                                <td>{elt.is_admin?"Oui":"Non"}</td>
                                <td>{elt.is_je?"Oui":"Non"}</td>
                                <td>
                                    <button class="btn-edit" onClick={() => launchModification(elt.id)}>Modifier</button>
                                </td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </>:<>

                {(!modeAjout)?<>
                    <button id="back-button" onClick={handleBack}>Retour</button>
                    <h4>Modification des rôles d'un utilisateur</h4>
                    {(member)?<>
                        <h5>Utilisateur:</h5>
                        <div className="text">
                            <p>Email: {member.email}</p>
                            <p>Prenom: {member.name}</p>
                            <p>Nom: {member.last_name}</p>
                        </div>

                        <h5>Rôles spéciaux:</h5>
                        <div className="superRole">
                            <button onClick={handleJE} className={(!isJe)?"activateJE":""} id="JE">{(!isJe)?"PROMOUVOIR A LA JE":"ENLEVER DE LA JE"}</button>
                            <button onClick={handleAdmin} className={(!isAdmin)?"activateADMIN":""} id="ADMIN">{(!isAdmin)?"PROMOUVOIR ADMIN":"ENLEVER ADMIN"}</button> 
                        </div>

                        <h5>Les Bureaux auxquels il appartient:</h5>
                        <button className="button" onClick={launchOffice}><img src={plusImg}/>Ajouter</button>
                        <div className="offices">
                            {(offices)?<>
                                {offices.length==0?<>
                                    <p>Aucun bureau...</p>
                                </>:<>
                                    {offices.map((elt,key) => (<div key={key} className="elt">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                            <button onClick={() => handleDeleteRelation(elt.id, "office")}><img src={closeImg} /></button>
                                        </div>
                                        <img src={elt.picture}/>
                                    </div>))}
                                </>}
                            </>:<>
                                <div className="elt">
                                    <p><Skeleton count={3}/></p>
                                </div>
                            </>}
                        </div>

                        <h5>Les Clubs auxquels il appartient:</h5>
                        <button className="button" onClick={launchClub}><img src={plusImg}/>Ajouter</button>
                        <div className="offices">
                            {(clubs)?<>
                                {clubs.length==0?<>
                                    <p>Aucun club...</p>
                                </>:<>
                                    {clubs.map((elt,key) => (<div key={key} className="elt">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                            <button onClick={() => handleDeleteRelation(elt.id, "club")}><img src={closeImg} /></button>
                                        </div>
                                        <img src={elt.picture}/>
                                    </div>))}
                                </>}
                            </>:<>
                                <div className="elt">
                                    <p><Skeleton count={3}/></p>
                                </div>
                            </>}
                        </div>


                        <h5>Test de notification pour cet utilisateur</h5>

                        <button className="notif-button" onClick={sendNotification}>Envoie de la notification</button>

                        <div className="bottom"></div>
                    </>:<>
                        <h5>Utilisateur:</h5>
                        <div className="text">
                            <p><Skeleton count={3}/></p>
                        </div>
                    </>}
                </>:<>
                    <button id="back-button" onClick={handleBackBack}>Retour</button>
                    {(modeAjout=="office")?<>
                        <h4>Choisir un Bureau pour cet utilisateur</h4>
                        <p>{member.last_name+" "+member.name}</p>
                        <div className="clubs">
                            {dataOffice?<>
                                {dataOffice.map((elt, key) => {
                                    return (<div key={key} className="element">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                        </div>
                                        <button onClick={() => addRelation(elt.id, "office")}>
                                            <img alt="logo" src={elt.picture} />
                                        </button>
                                    </div>);
                                })}
                            </>:<>
                                <div className="element">
                                    <div className="top">
                                        <h3><Skeleton /></h3>
                                    </div>
                                    <div className="top">
                                        <h3><Skeleton height={100}/></h3>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </>:<>
                        <h4>Choisir un Club pour cet utilisateur</h4>
                        <p>{member.last_name+" "+member.name}</p>
                        <div className="clubs">
                            {dataClub?<>
                                {dataClub.map((elt, key) => {
                                    return (<div key={key} className="element">
                                        <div className="top">
                                            <h3>{elt.name}</h3>
                                        </div>
                                        <button onClick={() => addRelation(elt.id, "club")}>
                                            <img alt="logo" src={elt.picture} />
                                        </button>
                                    </div>);
                                })}
                            </>:<>
                                <div className="element">
                                    <div className="top">
                                        <h3><Skeleton /></h3>
                                    </div>
                                    <div className="top">
                                        <h3><Skeleton height={100}/></h3>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </>}
                </>}
            </>}
            <div className="bottom"></div>
        </div>
        <Menu/>
    </div>);
};