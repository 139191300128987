//images
import logo from '../images/icon.png';
import playstoreImg from '../images/playstore.png';
import appstoreImg from '../images/appstore.png';

//css
import '../css/App.css';
import 'react-notifications/lib/notifications.css';

//routes
import HomePage from '../routes/Home';
import LoginPage from '../routes/Login';
import SignUpPage from '../routes/SignUp';
import EventsPage from '../routes/Events';
import UserPage from '../routes/User';
import ForgetPage from '../routes/Forget';
import SubscriptionPage from '../routes/Subscription';

import ResetPasswordPage from '../routes/ResetPassword';
import ForgetMailPage from '../routes/ForgetMail';

import ServicePartnerPage from '../routes/ServicePartner';
import ServiceGoodiesPage from '../routes/ServiceGoodie';
import ServiceClubPage from '../routes/ServiceClub';
import ServicePsnPage from '../routes/ServicePsn';

import ServiceAdminPage from '../routes/Admin';
import ServiceOfficePage from '../routes/Office';

import ErrorPage from './ErrorPage';

//librairies
import {BrowserRouter, Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import React, {useEffect, useState} from 'react';

import PrivateRoute from '../utils/PrivateRoute';
import {getUser, isSessionFinished, isSessionNeedToBeReload, logout} from '../utils/utils';
import Privacy from '../routes/Privacy';

//main function
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  //si on a pas encore les infos, on récupére les infos
  if(!user){
    if(localStorage.getItem("token")){
      getUser((data) => {
        if(data){
          setUser(data);
        }else{
          logout(setUser);
        }
      });
    }
  }

  //si on est connecté et qu'on a les infos
  if(localStorage.getItem("token")){
    //on vérifie si la session n'est pas terminé
    isSessionFinished((rep) =>{
      if(rep){
        logout(setUser);
      }else{
        //on vérifie si la session n'a pas besoin d'être relancé
        isSessionNeedToBeReload((rep) => {

        });
      }
    });
  }

  return (
    <>
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage user={user} setUser={setUser}/>} />
        <Route path="/events" element={<EventsPage user={user} setUser={setUser}/>} />
        <Route path="/me" element={<UserPage user={user} setUser={setUser}/>} />
        
        <Route path="/services/partners" element={<ServicePartnerPage user={user} setUser={setUser}/>} />
        <Route path="/services/club" element={<ServiceClubPage user={user} setUser={setUser}/>} />
        <Route path="/services/goodies" element={<ServiceGoodiesPage user={user} setUser={setUser}/>} />
        <Route path="/services/psn" element={<ServicePsnPage user={user} setUser={setUser}/>} />

        
        <Route path="/services/admin_office" element={<PrivateRoute access={user&&user.is_admin_office} user={user} setUser={setUser}>
                                    <ServiceOfficePage user={user} setUser={setUser}/>
                                </PrivateRoute>} />
        <Route path="/services/admin" element={<PrivateRoute user={user} setUser={setUser}>
                                    <ServiceAdminPage user={user} setUser={setUser}/>
                                </PrivateRoute>} />
        <Route path="/login" element={<LoginPage user={user} setUser={setUser} />} />
        <Route path="/signup" element={<SignUpPage user={user} setUser={setUser} />} />
        <Route path="/forget" element={<ForgetPage user={user} setUser={setUser}/>} />
        <Route path="/reset-password" element={<ResetPasswordPage user={user} setUser={setUser} />} />
        <Route path="/resend-mail" element={<ForgetMailPage user={user} setUser={setUser} />} />
        <Route path="/subscription" element={<SubscriptionPage user={user} setUser={setUser}/>}/>
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <NotificationContainer />
    </div>
    <div className="AppNotMobile">
      <div className="center">
        <div className="head">
          <img alt="logo" src={logo}/>
          <p>Cette application n'est pas accessible sur cette taille d'écran.</p>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
