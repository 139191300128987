import NavBar from '../components/Nav';
import React, {useState, useEffect} from 'react';

import {Link, useLocation, useNavigate, Navigate, useSearchParams} from 'react-router-dom';

import { NotificationManager } from 'react-notifications';
import {Buffer} from 'buffer';

import logo from '../images/icon.png';
import arrowImg from '../images/left-arrow.png';
import DialogLoading from '../utils/DialogLoading';

const LoginPage = (props) => {

    const {user, setUser} = props;

    const [queryParameters] = useSearchParams();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const err = queryParameters.get("err");
        const msg = queryParameters.get("msg");
        if(err){
            NotificationManager.warning(err);
        }
        if(msg){
            NotificationManager.info(msg);
        }
    }, []);

    if(user){
        return (<Navigate to="/" replace/>);
    }


    const handleKey = (e) => {
        if(e.key == "Enter"){
            handleSend();
        }
    }

    const handleSend = () => {
        setLoading(true);
        fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password
            })
        }).then(async rep => {
            setLoading(false);
            let data = await rep.json();
            if(rep.status === 200){
                if(data.message){
                    NotificationManager.success(data.message);
                }
                const token = String(data.token);

                const expiration = parseInt(JSON.parse(Buffer.from(token.split(".")[1], "base64").toString("utf-8"))["exp"])*1000;
                const startsession = (new Date()).getTime();
                localStorage.setItem("token", token);
                localStorage.setItem("expiration", expiration);
                localStorage.setItem("startsession", startsession);


                if(data.user){
                    setUser(data.user);
                }
                //on retourne sur la page d'accueil
                navigate("/");
            }else{
                if(data.data&&data.data.error){
                    NotificationManager.warning(data.data.error);
                }else if(data.message){
                    NotificationManager.warning(data.message);
                }
            }
        }).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };

    const width = window.screen.width;
    const height = window.screen.height/2;

    return (<div className="page" id="loginPage">
        <DialogLoading active={loading} setActive={setLoading}/>
        <div className="topdesign">
            <svg  class="svg">
            <path id="curve" fill="#df2222" d={`M ${width} ${height/5.5} Q ${width/1.1} ${height/5} ${width/1.3} ${height/5} Q ${width/2.5} ${height/5.5} ${width/4} ${height/3} Q ${width/5} ${height/2.6} 0 ${height/1.9} L 0 0 L ${width} 0 Z`}/>
            </svg>
        </div>
        <div className='backButton'>
            <Link to="/"><img alt="<" src={arrowImg}/></Link>
        </div>
        <div className="form">
            <div className="head">
                <img alt="CDE" src={logo}/>
                <h3>Connectez-vous à votre compte</h3>
            </div>
            
            <div className="middle">
                <div>
                    <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                    <input type="password" onKeyDown={handleKey} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe"/>
                </div>

                <button onClick={handleSend}>></button>
            </div>

            <div className="foot">
                <Link to="/signup">Vous n'êtes pas inscrit ?</Link>
                <Link to="/forget">Mot de passe oublié ?</Link>
                <Link to="/resend-mail">Mail non-reçu ?</Link>
            </div>
        </div>
        <div className="bottomdesign">
            <svg  class="svg">
                    <path id="curve" fill="#1b1919" d={`M ${width} ${height/5.5} Q ${width/1.1} ${height/5} ${width/1.3} ${height/5} Q ${width/2.5} ${height/5.5} ${width/4} ${height/3} Q ${width/5} ${height/2.6} 0 ${height/1.9} L 0 0 L ${width} 0 Z`}/>
            </svg>
        </div>
    </div>)
}

export default LoginPage;