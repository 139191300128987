import React, {useState, useEffect} from 'react';

import {Link, useLocation, useNavigate, Navigate, useSearchParams} from 'react-router-dom';

import { NotificationManager } from 'react-notifications';

import logo from '../images/icon.png';
import DialogLoading from '../utils/DialogLoading';

const ResetPasswordPage = (props) => {

    const {setUser, user} = props;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const [queryParameters] = useSearchParams();

    const navigate = useNavigate();
    const location = useLocation();

    const loadData = () => {
        fetch("/api/signup/reset_password?t="+encodeURIComponent(queryParameters.get("t")), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async rep => {
            const data = await rep.json();
            if(rep.status === 200){
                // if(data.message){
                //     NotificationManager.success(data.message);
                // }

                if(data.data){
                    setEmail(data.data.email);
                    setNom(data.data.nom);
                    setPrenom(data.data.prenom);
                }
            }else{
                if(data.data&&data.data.error){
                    NotificationManager.warning(data.data.error);
                }else if(data.message){
                    NotificationManager.warning(data.message);
                }
                navigate("/login");
            }
        }).catch(e => {
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    }

    useEffect(() => {
        loadData();
    },[]);

    if(user){
        return (<Navigate to="/" replace/>);
    }

    const handleKey = (e) => {
        if(e.key == "Enter"){
            handleSend();
        }
    }

    const handleSend = () => {
        setLoading(true);
        fetch("/api/signup/reset_password?t="+encodeURIComponent(queryParameters.get("t")), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                password,
                confirm_password: confirmationPassword
            })
        }).then(async rep => {
            setLoading(false);
            const data = await rep.json();
            if(rep.status === 200){
                if(data.message){
                    NotificationManager.success(data.message);
                }


                //on retourne sur la page d'accueil
                navigate("/login");
            }else{
                if(data.data&&data.data.error){
                    NotificationManager.warning(data.data.error);
                }else if(data.message){
                    NotificationManager.warning(data.message);
                }
            }
        }).catch(e => {
            setLoading(false);
            NotificationManager.warning("Il y a eu une erreur côté serveur...");
        })
    };


    const width = window.screen.width;
    const height = window.screen.height/2;

    return (<div className="page" id="signUpPage">
        <DialogLoading active={loading} setActive={setLoading} />
        <div className="topdesign">
            <svg  class="svg">
                <path id="curve" fill="#df2222" d={`M ${width} ${height/5.5} Q ${width/1.1} ${height/5} ${width/1.3} ${height/5} Q ${width/2.5} ${height/5.5} ${width/4} ${height/3} Q ${width/5} ${height/2.6} 0 ${height/1.9} L 0 0 L ${width} 0 Z`}/>
            </svg>
        </div>
        <div className="form">
            <div className="head">
                <img alt="CDE" src={logo}/>
                <h3>Mot de passe oublié</h3>
                <p>Veuillez rentrer votre nouveau mot de passe !</p>
            </div>
            
            <div className="middle">
                <div>
                    <input type="text" placeholder={email} disabled={true}/>
                    <input type="text" placeholder={nom} disabled={true}/>
                    <input type="text" placeholder={prenom} disabled={true}/>
                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe"/>
                    <input type="password" onKeyDown={handleKey} onChange={(e) => setConfirmationPassword(e.target.value)} placeholder="Confirmation du mot de passe"/>
                </div>

                <button onClick={handleSend}>></button>
            </div>

            <div className="foot">
            </div>
        </div>
        <div className="bottomdesign">
            <svg  class="svg">
                    <path id="curve" fill="#1b1919" d={`M ${width} ${height/5.5} Q ${width/1.1} ${height/5} ${width/1.3} ${height/5} Q ${width/2.5} ${height/5.5} ${width/4} ${height/3} Q ${width/5} ${height/2.6} 0 ${height/1.9} L 0 0 L ${width} 0 Z`}/>
            </svg>
        </div>
    </div>)
}

export default ResetPasswordPage;